/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { FormGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import ProgressBar from '../../components/ProgressBar';
import Layout from '../../layout/layout';
import {
  generateDocument,
  getAnswersByTemplateFields, getDocument, patchTemplateAnswer, postTemplateAnswer,
} from '../../store/actions/documentGenerator';

function DocumentGeneratorQuestions() {
  const [document, setDocument] = React.useState(null);
  const [answers, setAnswers] = React.useState([]);
  const [currentAnswer, setCurrentAnswer] = React.useState({});
  const { id } = useParams();
  const [previousCount, setPreviousCount] = React.useState(0);

  const navigate = useNavigate();

  const loadAnswersBytemplateFields = (templateFields) => {
    getAnswersByTemplateFields(templateFields).then((resAnswers) => {
      setAnswers(resAnswers);
    });
  };

  useEffect(() => {
    getDocument(id).then((resDocument) => {
      loadAnswersBytemplateFields(resDocument.templateFields);
      setDocument(resDocument);
    });
  }, []);

  const truncFloatToInteger = (value) => {
    const trunc = Math.trunc(value);
    if (trunc === value) {
      return trunc;
    }
    return trunc + 1;
  };

  const progressOfAnswers = () => {
    let progress = 0;
    if (document?.templateFields.length) {
      progress = (answers.length / document.templateFields.length) * 100;
    }
    return truncFloatToInteger(progress);
  };

  const detectStringBracketsAndReplaceToUnderline = (string) => {
    const regex = /{(.*?)}/g;
    const matches = string.match(regex);
    let newString = string;
    if (matches) {
      matches.forEach((match, index) => {
        newString = newString.replaceAll(match, answers[index]?.answer || '_____________');
      });
    }
    return newString;
  };

  const nextQuestion = document?.templateFields[answers.length - previousCount];

  const goToPreviousQuestion = () => {
    setPreviousCount(previousCount + 1);
    setCurrentAnswer(answers[answers.length - previousCount - 1]);
  };

  const saveCurrentAnswer = (question) => {
    if (currentAnswer.answer) {
      const answer = {
        ...currentAnswer,
        templateFieldsId: question.id,
      };

      const saveOrUpdateAnswer = previousCount !== 0 ? patchTemplateAnswer : postTemplateAnswer;

      saveOrUpdateAnswer(answer).then(() => {
        loadAnswersBytemplateFields(document.templateFields);
        setCurrentAnswer(answers[answers.length - previousCount + 1] || { answer: '' });
        setPreviousCount(previousCount - 1 < 0 ? 0 : previousCount - 1);
      });
    }
  };

  const finishDocument = () => {
    generateDocument(document).then(() => {
      navigate(`/tools/templates/${document?.id}`);
    });
  };

  return (
    <Layout title={document?.name} subtitle="Ferramentas / Gerador de documentos / Novo documento">
      <Row>

        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <p>Preencha as respostas para gerar o seu documento</p>
              <ProgressBar progress={progressOfAnswers()} />
            </div>
          </div>
          <div className="margin-top" />
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="card-title">Respostas</h5>

              <FormGroup>
                <label htmlFor="question">
                  {nextQuestion?.label}
                </label>
                <input type="text" value={currentAnswer?.answer} onChange={(e) => setCurrentAnswer({ ...currentAnswer, answer: e.target.value })} className="form-control" id="question" placeholder={nextQuestion?.placeholder} />
              </FormGroup>
              <br />
              <button type="button" className="btn btn-secondary float-start" onClick={goToPreviousQuestion}>Anterior</button>
              {nextQuestion
                ? <button type="button" className="btn btn-primary float-end" onClick={() => saveCurrentAnswer(nextQuestion)}>Próxima</button>
                : <button type="button" className="btn btn-primary float-end" onClick={() => finishDocument(nextQuestion)}>Finalizar</button>}
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <div style={{ maxHeight: '560px' }} className="overflow-auto">
                {parse(detectStringBracketsAndReplaceToUnderline(document?.file?.convertedToHtml || ''))}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Layout>
  );
}

export default DocumentGeneratorQuestions;
