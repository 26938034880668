import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../logo.svg';
import './sidebar.css';

const customer = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Gerador de documentos',
    href: '/tools/templates',
  },
  {
    label: 'Mapeamento de dados',
    href: '/tools/sectors',
  },
  {
    label: 'Gerenciador de marcas',
    href: '/marcas',
  },
];

const backoffice = [
  {
    label: 'Gerador de documentos',
    href: '/admin/tools/templates',
  },
  {
    label: 'Mapeamento de dados',
    href: '/admin/tools/mapping',
  },
  {
    label: 'Gerenciador de Marcas',
    href: '/admin/marcas',
  },
  {
    label: 'Usuários',
    href: '/admin/users',
  },
  {
    label: 'Área como cliente',
    childs: customer,
  },
];

const menuItens = {
  customer,
  backoffice,
};

function Item({ menuItem }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <li className={`nav-item ${location.pathname === menuItem.href ? 'active' : null}`}>
      <a className="nav-link" onClick={() => navigate(menuItem.href)} href>{menuItem.label}</a>
    </li>
  );
}

function ItemTree({ menuItem }) {
  const ChildItens = menuItem.childs.map((childItem) => <Item menuItem={childItem} />);
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle "
        href
        role="button"
        data-bs-toggle="dropdown"
      >
        {menuItem.label}

      </a>
      <ul className="dropdown-menu bg-dark">
        <div className="container-fluid">
          {ChildItens}
        </div>
      </ul>
    </li>
  );
}

function RenderMenuItems({ menuItems }) {
  return menuItems.map((item) => {
    if (item.childs) {
      return <ItemTree menuItem={item} />;
    }
    return <Item menuItem={item} />;
  });
}
function Sidebar({ userLogged }) {
  let menuItensToShow = menuItens.customer;
  if (userLogged?.isBackoffice) {
    menuItensToShow = menuItens.backoffice;
  }

  return (
    <nav className="navbar navbar-dark">
      <div className="container-fluid justify-content-center">
        <img src={logo} alt="Logo" height="150" width="150" />
      </div>
      <div className="col-md-12 ct-sidebar" id="mynavbar">
        <ul className="navbar-nav">
          <RenderMenuItems menuItems={menuItensToShow} />
        </ul>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  userLogged: state.userLogged,
});

export default connect(mapStateToProps, null)(Sidebar);
