import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CreateFileMarca } from '../store/actions/marcas';
import UploadForm from './formUpload';
import { validationUploadGLobal, validationUploadJPEG } from '../store/actions/validationsUpload';

function Step3MarcaForm({
  step, setStep, dataMarca, setDataMarca, setMarca,
}) {
  const [details, setDetails] = useState('');
  const [file, setFile] = useState(null);
  const [fileDocument, setFileDocument] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!details || !fileDocument) {
      return toast.error('Preencha todos os dados.');
    }
    if (dataMarca[0].presentation !== 'nominativa' && !file) {
      return toast.error('Preencha todos os dados.');
    }

    const formData = new FormData();
    formData.append('file', fileDocument);

    const resultUpImg = await CreateFileMarca(formData);

    let resultUpDoc;
    if (file) {
      const formDataDoc = new FormData();
      formDataDoc.append('file', file);
      resultUpDoc = await CreateFileMarca(formDataDoc);
    }

    setDataMarca([...dataMarca,
      { description: details, logoFileId: resultUpImg, socialContractFileId: resultUpDoc },
    ]);
    setMarca(true);
    return true;
  };

  return (
    <Form onSubmit={handleSubmit}>
      {
        dataMarca[0].presentation !== 'nominativa' ? (
          <UploadForm
            setFile={setFile}
            title="Enviar imagem"
            rules="Formato: JPEG (para imagens) | Tamanho: 8cm x 8cm (945 x 945 pixeis) | Tamanho máximo: 2MB"
            validation={validationUploadJPEG}
          />
        ) : null
      }

      <Form.Group className="mt-1" controlId="file">
        <UploadForm
          setFile={setFileDocument}
          title="Upload do Contrato social"
          validation={validationUploadGLobal}
        />
      </Form.Group>

      <Form.Group
        className="mt-1"
        controlId="details"
      >
        <Form.Label>Detalhes do serviço</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Digite os detalhes do serviço"
          value={details}
          onChange={(event) => setDetails(event.target.value)}
        />
      </Form.Group>

      <div className="d-flex justify-content-end">
        <Button
          className="mt-3 btn-lg"
          variant="success"
          type="submit"
        >
          Enviar
        </Button>
      </div>
      <div className="mt-3 mb-3">
        {step > 1 && (
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => {
              setStep(step - 1);
              // const list = dataMarca[0];
              // setDataMarca([list]);
            }}
          >
            Voltar
          </Button>
        )}
      </div>
    </Form>
  );
}

export default Step3MarcaForm;
