import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ContainerPasswordGenererate({ passHash, handleModal }) {
  return (
    <>
      <div style={{ width: '100%' }} className="d-flex align-items-center bg-danger text-white">
        <h3 className="d-flex align-items-center p-3">NÃO COMPARTILHE A SENHA</h3>
      </div>
      <Modal.Body>
        <p className="lead mb-0">
          A senha do usuário cadastrado é:
        </p>
        <strong>
          <span className="text-danger">{passHash.value}</span>
        </strong>
      </Modal.Body>
      <Button variant="outline-danger" onClick={() => handleModal(false)}>
        Fechar
      </Button>

    </>
  );
}

export default ContainerPasswordGenererate;
