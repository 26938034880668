import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { getDocuments } from '../store/actions/documentGenerator';
import DocumentCard from './DocumentCard';

function LoadDocumentsInCards() {
  const [documents, setDocuments] = React.useState([]);

  useEffect(() => {
    getDocuments().then((response) => {
      setDocuments(response.rows);
    });
  }, []);

  return (
    <Row>
      {documents?.map((document) => (
        <div className="col-md-3">
          <DocumentCard document={document} />
        </div>
      ))}

    </Row>
  );
}

export default LoadDocumentsInCards;
