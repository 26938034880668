import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ToggleButtonWithDelete from '../../components/ToggleButton';
import ToggleModel from '../../components/ToggleModel';
import Layout from '../../layout/layout';
import {
  CreateActivitySector, deleteActivitySector, getByIdSector,
} from '../../store/actions/sectors';

function SectorActivities() {
  const [showModal, setShowModal] = useState(false);
  const [sector, setSector] = useState(null);
  const [updateComponent, setUpdateCOmponent] = useState(0);
  const id = localStorage.getItem('@:department');
  const navigate = useNavigate();

  useEffect(() => {
    async function data() {
      const sertorFound = await getByIdSector(id);
      setSector(sertorFound);
    }
    data();
  }, [updateComponent]);

  const handleAddActivity = async ({ name, sectorId }) => {
    const result = { name, sectorId };
    await CreateActivitySector(result);
  };

  const handleDeleteActivity = async (atvivityId) => {
    await deleteActivitySector(atvivityId);
    setUpdateCOmponent(updateComponent + 1);
  };

  return (
    <Layout title={`Atividades do setor ${sector?.name}`} subtitle="Ferramentas">
      <Container className="my-5">
        <Row className="mb-5 mx-auto px-5">
          <Col>
            <Card bg="white" text="dark" className="mb-3">
              <Card.Body>
                <Card.Text>
                  Aqui você deve preencher todas as atividades
                  que a sua empresa executa e que envolvem dados que
                  identificam ou podem identificar pessoa físicas
                </Card.Text>
                <Card.Text as="p" className="mb-0">
                  <strong>Ex: </strong>
                  Execução de serviços com cliente, atendimento ao
                  cliente, marketing, prospecção de vendas, suporte,
                  cobrança, sucesso do cliente, gestão da equipe.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card bg="warning" text="white" className="mb-3">
              <Card.Body>
                <Card.Text>
                  <strong>Atenção!</strong>
                  {' '}
                  Indique todas as atividades, não somente as citadas nos exemplos
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-5 mx-auto px-5">
          <ListGroup variant="flush" className="flex-row flex-wrap">
            {sector?.Activites?.map((activity) => (
              <ToggleButtonWithDelete
                name={activity.name}
                onDelete={() => handleDeleteActivity(activity.id)}
                onClickButton={async () => {
                  localStorage.setItem('@:activityId', activity.id);
                  if (!activity?.AnswerMappings[0]?.id) {
                    navigate('/tools/mapping');
                  } else {
                    localStorage.removeItem('@:anwserId');
                    navigate('/tools/mapping');
                  }
                }}
              />
            ))}
          </ListGroup>
        </Row>

        <Row className="justify-content-end mb-5">
          <Col md={3}>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => navigate('/tools/sectors')}
            >
              Voltar
            </Button>
          </Col>
          <Col md={3}>
            <Button
              variant="primary"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Adicionar atividade
            </Button>
          </Col>
        </Row>

        <ToggleModel
          name="Criar Atividade"
          showModal={showModal}
          setShowModal={setShowModal}
          addAnswer={handleAddActivity}
          sectorId={id}
          updateGlobal={() => setUpdateCOmponent(updateComponent + 1)}
        />

      </Container>
    </Layout>
  );
}

export default SectorActivities;
