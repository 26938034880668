import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  ListGroup,
  Button,
  Form,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import Layout from '../../layout/layout';
import ToggleButtonWithDelete from '../../components/ToggleButton';
import { CreateSector, deleteSector, getAllSector } from '../../store/actions/sectors';
import { generateXlsxFromAnwsers, getDataMappings } from '../../store/actions/mapping';
import { formatStringToLocale } from '../../utils/dateTime';
import { donwloadBlobFile } from '../../store/actions/files';

const departments = [
  { id: 1, name: 'RH' },
  { id: 2, name: 'Marketing' },
  { id: 3, name: 'Financeiro' },
  { id: 4, name: 'Tecnologia' },
  { id: 5, name: 'Operações' },
  { id: 6, name: 'Comercial' },
  { id: 7, name: 'Suprimentos' },
  { id: 8, name: 'Recursos Humanos' },
  { id: 9, name: 'Qualidade' },
  { id: 10, name: 'Logística' },
  { id: 11, name: 'Pesquisa e Desenvolvimento' },
  { id: 12, name: 'Suporte Técnico' },
];

function Sectors() {
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [updateList, setUpdateList] = useState(0);
  const [dataMappings, setDataMappings] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function handleSectors() {
      const result = await getAllSector();
      const mappings = await getDataMappings();

      setDataMappings(mappings);
      setSelectedDepartments(result.rows);
    }
    handleSectors();
  }, [updateList]);

  const handleSelectDepartment = async (nameValue) => {
    const isAlreadySelected = selectedDepartments.some((d) => d.name === nameValue);
    if (!isAlreadySelected) {
      const selectedDepartment = departments.find((d) => d.name === nameValue);
      setSelectedDepartments([...selectedDepartments, selectedDepartment]);
    }
    await CreateSector({ name: nameValue });
    setUpdateList(updateList + 1000);
  };
  const visibleDepartments = departments.filter(
    (department) => !selectedDepartments.some(
      (d) => d.name === department.name,
    ),
  );

  const handleDeleteSector = async (id) => {
    await deleteSector(id);
    setUpdateList(updateList + 1);
  };

  const columns = [
    {
      name: 'Titulo',
      selector: (row) => row.title,
    },
    {
      name: 'Criado em',
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: 'Ações',
      selector: (row) => row.actions,
    },
  ];

  const data = dataMappings?.rows?.map((dataMapping) => ({
    title: 'Mapeamento realizado',
    createdAt: formatStringToLocale({ date: dataMapping.createdAt }),
    actions: (
      <Form.Group>
        <button
          onClick={() => donwloadBlobFile({
            id: dataMapping.fileId,
            filename: `Mapeamento de dados ${dataMapping.createdAt}.xlsx`,
          })}
          type="button"
          className="btn btn-secondary"
        >
          Download
          {' '}
          <span><FontAwesomeIcon icon={faArrowAltCircleDown} /></span>
        </button>
      </Form.Group>
    ),
  }));

  return (
    <Layout title="Mapeamentos" subtitle="Ferramentas">
      <Row className="my-5">
        <div className="col-10">
          <Card className="mb-4">
            <Card.Header as="h5">Setores disponíveis</Card.Header>
            <ListGroup variant="flush" className="flex-row flex-wrap justify-content-center p-3">
              {visibleDepartments.map((department) => (
                <ToggleButtonWithDelete
                  name={department.name}
                  isChecked
                  key={department.id}
                  action
                  onClickButton={() => {
                    handleSelectDepartment(department.name);
                  }}
                  className={selectedDepartments?.some((d) => d.name === department.name) ? 'd-none' : ''}
                >
                  {department.name}
                </ToggleButtonWithDelete>
              ))}
            </ListGroup>
            <Card.Header as="h5">Seus Setores</Card.Header>
            <ListGroup variant="flush" className="flex-row flex-wrap justify-content-center p-3">
              {selectedDepartments.map((department) => (
                <ToggleButtonWithDelete
                  key={department.id}
                  name={department.name}
                  onDelete={() => handleDeleteSector(department.id)}
                  onClickButton={() => {
                    localStorage.setItem('@:department', department.id);
                    navigate('/tools/sectors/activities');
                  }}
                />
              ))}
            </ListGroup>
            {selectedDepartments.length > 0 && (
              <Button onClick={() => {
                generateXlsxFromAnwsers();
              }}
              >
                Gerar mapeamento

              </Button>
            )}
          </Card>
        </div>
        <div className="col-10">
          <Card className="w-100">
            <Card.Header as="h5">Mapeamentos realizados</Card.Header>
            <Card.Body>
              {dataMappings?.rows?.length === 0 && (
                <p>Nenhum documento encontrado</p>
              )}
              {dataMappings?.rows?.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Layout>
  );
}

export default Sectors;
