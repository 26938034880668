import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

function ToggleModel({
  showModal, setShowModal, updateGlobal, questionId, name,
  addAnswer, sectorId, answerId, label, addProcess,
}) {
  const [InputModelValue, setName] = useState('');

  const handleAnswerNameChange = (event) => {
    setName(event.target.value);
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleAddAnswer = async () => {
    if (addAnswer) {
      await addAnswer({ name: InputModelValue, questionId, sectorId }, answerId);
      updateGlobal();
      setShowModal(false);
    }
    if (addProcess) {
      const brandId = answerId;
      addProcess({ id: InputModelValue, brandId });
    }
    setName('');
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>{label || 'resposta:'}</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder="Digite a resposta"
            value={InputModelValue}
            onChange={handleAnswerNameChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleModal}>
          Cancelar
        </Button>
        <Button variant="success" onClick={handleAddAnswer}>
          Adicionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ToggleModel;
