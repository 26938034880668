import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updatePassword, verifyPassword } from '../../store/actions/users';

function ChangePasswordForm({ userProfile, ...rest }) {
  const { handleClose } = rest;
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (event) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error('As senhas não conferem');
      return;
    }
    if (passwords.newPassword.length < 8) {
      toast.error('A senha deve ter no mínimo 8 caracteres');
      return;
    }
    if (passwords.newPassword === passwords.oldPassword) {
      toast.error('A nova senha deve ser diferente da antiga');
      return;
    }
    if (!passwords.newPassword || !passwords.oldPassword || !passwords.confirmPassword) {
      toast.error('Preencha todos os campos');
      return;
    }
    await verifyPassword({
      email: userProfile.email,
      password: passwords.oldPassword,
      validPassword: 'validation',
    });

    await updatePassword(userProfile.id, { password: passwords.newPassword });
    handleClose();
  };

  const handleInputChange = ({
    label, name, type, placeholder,
  }) => (
    <Form.Group controlId={`form${name}`} key={name} className="mb-2">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        value={passwords[name]}
        onChange={handleChange}
      />
    </Form.Group>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {handleInputChange({
        label: 'Senha antiga', name: 'oldPassword', type: 'password', placeholder: 'Digite sua senha antiga',
      })}
      {handleInputChange({
        label: 'Nova senha', name: 'newPassword', type: 'password', placeholder: 'Digite sua nova senha',
      })}
      {handleInputChange({
        label: 'Confirmar nova senha', name: 'confirmPassword', type: 'password', placeholder: 'Confirme sua nova senha',
      })}
      <Button variant="primary" type="submit" className="mt-3">
        Alterar senha
      </Button>
    </Form>
  );
}

export default ChangePasswordForm;
