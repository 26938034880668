import React from 'react';
import { Card } from 'react-bootstrap';

function InfoCard({
  title, info, infoSecundary, infoEnd,
}) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <hr />
        <Card.Text className="card-text-uppercase-bold">
          {info}
        </Card.Text>
        {
          infoSecundary && (
            <>
              <hr />
              <Card.Text className="card-text-uppercase-bold">
                {infoSecundary}
              </Card.Text>
            </>
          )
        }
        {
          infoEnd && (
          <>
            <hr />
            <Card.Text className="card-text-uppercase-bold">
              {infoEnd}
            </Card.Text>
          </>
          )
        }
      </Card.Body>
    </Card>
  );
}

export default InfoCard;
