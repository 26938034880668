import React, { useEffect, useState } from 'react';
import {
  Row, Form, Button, Card, Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormUser from '../../components/formUser';
import Layout from '../../layout/layout';
import { deleteUserOfCustumer, getAllUsersOfCustumer, getByIdUsersOfCustumer } from '../../store/actions/users';
import CustomModal from '../../components/modal';
import { openOrCloseModal } from '../../store/actions/modal';
import ContainerPasswordGenererate from '../../components/containerPasswordGenerate';
import ChangePasswordForm from './ChangePassword';

function UsersOfCustomer() {
  const [users, setUsers] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [optionHandle, setOptionHandle] = useState('Gerenciar usuários');

  const updateDataUser = (user, remove = true) => {
    setUsers((prevUsers) => (remove ? prevUsers.filter((u) => u.id !== user.id) : prevUsers));
  };

  const isOpenModal = useSelector((state) => state.modal);
  const passHash = useSelector((state) => state.passwordDefault);
  const dispatch = useDispatch();

  const handleModal = (value) => {
    dispatch(openOrCloseModal(value));
  };

  useEffect(() => {
    async function findUserOrUsers() {
      if (optionHandle === 'Gerenciar usuários') {
        setUsers(await getAllUsersOfCustumer());
      } else {
        const userIdProfiler = (await getByIdUsersOfCustumer());
        const findUser = users.find((user) => user?.CustomerId === userIdProfiler?.CustomerId);
        setUserProfile(findUser);
      }
    }
    findUserOrUsers();
  }, [optionHandle]);

  const handleOptionClick = (option) => {
    setOptionHandle(option);
  };

  const handleDeleteUser = async (user) => {
    await deleteUserOfCustumer(user.id);
    updateDataUser(user);
  };

  const handleDeleteUserProfile = async () => {
    await deleteUserOfCustumer(userProfile.id);
    setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userProfile.id));
  };

  return (
    <Layout title="Gerenciar usuários" subtitle="Ferramentas">
      <Row className="my-5">
        <Col className="mb-5">
          <Button
            variant={optionHandle !== 'Gerenciar usuários' ? 'secondary' : 'outline-secondary'}
            disabled={optionHandle !== 'Gerenciar usuários'}
            size="lg"
            className="mx-1"
            onClick={() => handleOptionClick('Usuário')}
          >
            Dados do usuário
          </Button>
          <Button
            variant={optionHandle === 'Gerenciar usuários' ? 'secondary' : 'outline-secondary'}
            disabled={optionHandle === 'Gerenciar usuários'}
            size="lg"
            className="mx-1"
            onClick={() => handleOptionClick('Gerenciar usuários')}
          >
            Gerenciar usuários
          </Button>
        </Col>
        {optionHandle === 'Gerenciar usuários' && (
          <Form>
            <CustomModal
              title="AVISO!"
              isOpen={isOpenModal}
              onClose={() => handleModal(false)}
            >
              <ContainerPasswordGenererate passHash={passHash} handleModal={handleModal} />
            </CustomModal>
            <CustomModal
              title="Adicionar usuário"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
            >
              <FormUser
                updateData={() => updateDataUser(users, false)}
                create
                updateComponent={setOptionHandle}
                modal={() => {
                  setIsOpen(false);
                  handleModal(true);
                }}
                setData={setUsers}
                dataCreate={users}
                customerId={userProfile?.CustomerId}
              />
            </CustomModal>
            <Button className="mx-1 mt-2" variant="outline-primary" onClick={() => setIsOpen(true)}>
              Criar Usuário
            </Button>
            {users.map((user) => (
              <Card key={user.id} className="my-4">
                <Card.Body>
                  <FormUser data={user} isList onDelete={() => handleDeleteUser(user)} />
                </Card.Body>
              </Card>
            ))}
          </Form>
        )}
        { optionHandle === 'Usuário' && (
          <Card className="my-4">
            <Card.Body>
              <FormUser
                data={userProfile}
                isList
                onDelete={handleDeleteUserProfile}
              />
              <Button variant="secondary" size="sm" onClick={() => setIsOpen(true)}>Alterar senha</Button>
              <CustomModal
                title="Alterar senha"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              >
                <ChangePasswordForm
                  userProfile={userProfile}
                  handleClose={() => setIsOpen(false)}
                />
              </CustomModal>
            </Card.Body>
          </Card>
        )}
      </Row>
    </Layout>
  );
}

export default UsersOfCustomer;
