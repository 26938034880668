import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken } from './Auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status) {
      if (error.response.data.error) {
        toast.error(error.response.data.error, {
          toastId: error.response.status,
        });
      } else {
        switch (error.response.status) {
          case 400:
            toast.error('Requisição inválida.', {
              toastId: error.response.status,
            });
            break;
          case 401:
            toast.error('Não autorizado.', {
              toastId: error.response.status,
            });
            break;
          case 403:
            toast.error('Acesso negado.', {
              toastId: error.response.status,
            });
            break;
          case 404:
            toast.error('Não encontrado.', {
              toastId: error.response.status,
            });
            break;
          case 405:
            toast.error('Método não permitido.', {
              toastId: error.response.status,
            });
            break;
          case 500:
            toast.error('Erro interno do servidor.', {
              toastId: error.response.status,
            });
            break;
          default:
            toast.error('Oops, algo inesperado aconteceu.', {
              toastId: error.response.status,
            });
            break;
        }
      }
    } else {
      toast.error('Oops, algo inesperado aconteceu.');
    }

    return Promise.reject(error);
  },
);

export default api;
