import React, { useEffect, useState } from 'react';
import {
  Row, Col, Form, Button, Card,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { createUserOfCustumer, updateUserOfCustumer } from '../store/actions/users';
import { refreshToken } from '../store/actions/auth';
import { setPasswordDefault } from '../store/actions/passwordDefault';

function renderInputField(label, type, placeholder, isEditable, value, onChange) {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        className="mb-3"
        type={type}
        required
        placeholder={placeholder}
        disabled={isEditable}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

function FormUser({
  data, isList = false, create = false, onDelete, updateData, back,
  modal, setData, dataCreate, refreshTokenDispatch, userLogged,
}) {
  const [isEditable, setIsEditable] = useState(create);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [job, setJob] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setEmail(data?.email);
    setName(data?.name);
    setPhone(data?.phone);
    setJob(data?.job);
  }, [data]);

  const handleSave = async () => {
    const userData = {
      name,
      email,
      phone,
      job,
      isBackoffice: userLogged.isBackoffice,
      CustomerId: userLogged.CustomerId,
    };

    if (data) {
      await updateUserOfCustumer(data.id, userData);
      setIsEditable(false);
      await refreshTokenDispatch();
    } else {
      const handlePass = (value) => {
        dispatch(setPasswordDefault(value));
      };

      const password = uuid().replace(/-/g, '').substring(0, 8);
      handlePass(password);
      userData.password = password;
      await createUserOfCustumer(userData);
    }

    setIsEditable(false);
    if (updateData) {
      updateData();
    }
    if (modal) {
      modal();
      setData([...dataCreate, userData]);
    }
  };

  return (
    <Form.Group controlId={`question-${data?.id}`}>
      <Row>
        {
          isList ? (
            <Col className="d-flex justify-space-between mb-4">
              <Card.Title className="m-2">{data?.name?.toUpperCase() || `Usuário ${data?.email}`}</Card.Title>
              <Col xs={100} className="d-flex justify-content-end">
                <Button
                  className="mx-1"
                  variant="warning"
                  disabled={isEditable}
                  onClick={async () => setIsEditable(true)}
                >
                  Editar Usuário
                </Button>
                <Button
                  className="mx-1"
                  variant="danger"
                  onClick={onDelete}
                >
                  Deletar Usuário
                </Button>
              </Col>
            </Col>
          ) : <Card.Title className="m-2">{data?.name?.toUpperCase()}</Card.Title>
        }
      </Row>
      <Row>
        <Col>
          {renderInputField('Nome*', 'text', 'Digite o nome', !isEditable, name, (e) => { setName(e.target.value); })}
          {renderInputField('Email*', 'text', 'Digite o email', !isEditable, email, (e) => { setEmail(e.target.value); })}
          {renderInputField('Cargo*', 'text', 'Digite o cargo', !isEditable, job, (e) => { setJob(e.target.value); })}
          {renderInputField('Telefone*', 'text', 'Digite o telefone', !isEditable, phone, (e) => { setPhone(e.target.value); })}
          {
            isEditable && (
              <Button
                className="mx-1 mt-4 float-end"
                onClick={handleSave}
              >
                Salvar Usuário
              </Button>
            )
          }
          {
            back && (
            <Button
              className="mx-1 mt-4"
              variant="outline-secondary"
              onClick={back}
            >
              Voltar
            </Button>
            )
          }
        </Col>
      </Row>
    </Form.Group>
  );
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { refreshToken },
    dispatch,
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

export default connect(mapStateToProps, mapDispathToProps)(FormUser);
