import { toast } from 'react-toastify';
import api from '../../utils/Api';

const configFile = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
export const CreateFileMarca = async (formData) => toast.promise(api.post('/files', formData, configFile), {
  pending: 'Fazendo upload do documento...',
  success: 'Documento salvo.',
}).then((res) => res.data);

export const createMarcas = async (data) => toast.promise(api.post('/brands', data), {
  pending: 'Criando marca...',
  success: 'Marca criada.',
}).then((res) => res.data);

export const findMarcas = async () => toast.promise(api.get('/brands'), {
  pending: 'Procurando marcas...',
  success: 'Marcas encontradas.',
}).then((res) => res.data.rows);
