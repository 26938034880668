/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/layout';

function Home() {
  const navigate = useNavigate();
  return (
    <Layout title="Home">

      <Row>
        <div className="col-md-4">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Gerador de documentos</h5>
              <h6 className="card-subtitle mb-2 text-muted">Não dependa de ninguem</h6>
              <p className="card-text">Agora você mesmo pode gerar documentos como: Contrato de prestação de serviços, Termo de sigilo e muito mais</p>
              <a onClick={() => navigate('/tools/templates')} href className="card-link">Gerar agora</a>
            </div>
          </div>
        </div>
        <div className="col-md-4">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Mapeamento de dados</h5>
              <h6 className="card-subtitle mb-2 text-muted">Facilitando a LGPD</h6>
              <p className="card-text">Problemas com a LGPD ? vamos solucioná-los agora, você mesmo conseguirá dar os primeiros passos para a sua adequação</p>
              <a onClick={() => navigate('/tools/sectors')} href className="card-link">Mapear link</a>
            </div>
          </div>
        </div>
        <div className="col-md-4">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Registro de marcas</h5>
              <h6 className="card-subtitle mb-2 text-muted">Do jeito facil</h6>
              <p className="card-text">Chega de burocracias, inicie o registro de marca o quanto antes para que ninguém faça algo parecido</p>
              <a onClick={() => navigate('/marcas')} href className="card-link">Registrar agora</a>
            </div>
          </div>
        </div>
      </Row>
    </Layout>
  );
}

export default Home;
