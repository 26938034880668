/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import Layout from '../../layout/layout';
import { getAnswersByTemplateFields, getDocument, getDocumentsGenerated } from '../../store/actions/documentGenerator';
import { formatStringToLocale } from '../../utils/dateTime';
import { donwloadBlobFile } from '../../store/actions/files';

function DocumentGeneratorDetail() {
  const [document, setDocument] = React.useState(null);
  const [documentsGenerated, setDocumentsGenerated] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);

  const { id } = useParams();

  useEffect(() => {
    getDocument(id).then((resDocument) => {
      getDocumentsGenerated({
        where: {
          templateId: id,
        },
      }).then((documentsGenerateds) => {
        getAnswersByTemplateFields(resDocument.templateFields).then((resAnswers) => {
          setDocument(resDocument);
          setAnswers(resAnswers);
          setDocumentsGenerated(documentsGenerateds);
        });
      });
    });
  }, []);

  const columns = [
    {
      name: 'Titulo',
      selector: (row) => row.title,
    },
    {
      name: 'Criado em',
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: 'Ações',
      selector: (row) => row.actions,
    },
  ];

  const data = documentsGenerated?.rows?.map((documentGenerated) => ({
    title: document.name,
    createdAt: formatStringToLocale({ date: documentGenerated.createdAt }),
    actions: (
      <Form.Group>
        <button
          onClick={() => donwloadBlobFile({
            id: documentGenerated.fileId,
            filename: `${document?.name} ${documentGenerated.createdAt}.docx`,
          })}
          type="button"
          className="btn btn-secondary"
        >
          Download
          {' '}
          <span><FontAwesomeIcon icon={faArrowAltCircleDown} /></span>
        </button>
      </Form.Group>
    ),
  }));

  return (
    <Layout title={document?.name} subtitle="Ferramentas / Gerador de documentos">
      <Row>

        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <p>{document?.description}</p>
              <br />
              <p>
                Revisado pela ultima vez em:
                {' '}
                {formatStringToLocale({ date: document?.updatedAt })}
              </p>
            </div>
          </div>
          <div className="margin-top" />

        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <p><b>Seus documentos</b></p>
              <div>
                <div className="col-md-12">
                  {documentsGenerated?.rows?.length > 0 ? (
                    'Você possui os documentos gerados a baixo'
                  ) : (
                    'Você ainda não possui nenhum documento gerado'
                  )}
                  {' '}
                  <Link to={`/tools/templates/${document?.id}/questions`}>
                    <Button className="float-end">{answers.length > 0 ? 'Continuar documento' : 'Novo documento'}</Button>
                  </Link>

                </div>
                <div className="col-md-12">
                  <DataTable
                    columns={columns}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="margin-top" />

        </div>
      </Row>
    </Layout>
  );
}

export default DocumentGeneratorDetail;
