/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import LoadDocumentsInCards from '../../components/LoadDocumentsInCards';
import Layout from '../../layout/layout';

function DocumentGenerator() {
  return (
    <Layout title="Gerador de documentos" subtitle="Ferramentas">
      <Row>

        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Como funciona ? </h3>
              <Row>
                <div className="col-md-4">
                  <h5>1º Escolha um documento</h5>
                  <p>Selecione um dos vários documentos disponíveis para serem criados.</p>
                </div>
                <div className="col-md-4">
                  <h5>2º Preencha as informações</h5>
                  <p>Responda algumas perguntas.</p>
                </div>
                <div className="col-md-4">
                  <h5>3º Baixe seu novo documento</h5>
                  <p>Ta-Da, seu documento será criado automaticamente.</p>
                </div>
              </Row>
            </div>
          </div>
          <div className="margin-top" />
          <LoadDocumentsInCards />

        </div>
      </Row>
    </Layout>
  );
}

export default DocumentGenerator;
