import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const CreateQuestion = async (bodyQuestion) => toast.promise(api.post('/question', bodyQuestion), {
  pending: 'Criando pergunta...',
  success: 'Pergunta criada com sucesso.',
}).then((res) => res.data);

export const getAllQuestion = async () => toast.promise(api.get('/question'), {
  pending: 'Carregando perguntas...',
  success: 'Pergunta carregadas com sucesso.',
}).then((res) => res.data);

export const updateQuestion = async (bodyQuestion, idQuestion) => toast.promise(api.put(`/question/${idQuestion}`, { bodyQuestion }), {
  pending: 'Atualizando pergunta...',
  success: 'Pergunta atualizada com sucesso.',
}).then((res) => res.data);

export const deleteQuestion = async (idQuestion) => toast.promise(api.delete(`/question/${idQuestion}`), {
  pending: 'Deletando pergunta...',
  success: 'Pergunta deletada com sucesso.',
}).then((res) => res.data);

export const CreateAnswer = async (bodyAnswer) => toast.promise(api.post('/question/anwser/', bodyAnswer), {
  pending: 'Criando resposta...',
  success: 'Resposta criada com sucesso.',
}).then((res) => res.data);

export const deleteAnswer = async (idAnswer) => toast.promise(api.delete(`/question/anwser/${idAnswer}`), {
  pending: 'Deletando resposta...',
  success: 'Resposta Deletada com sucesso.',
}).then((res) => res.data);

export const generateXlsxFromAnwsers = async () => toast.promise(api.post('/question/generateXlsxFromAnwsers'), {
  pending: 'Gerando documento...',
  success: 'Documento gerado com sucesso.',
}).then((res) => res.data);

export const getDataMappings = async () => toast.promise(api.get('/dataMapping'), {
  pending: 'Carregando mapeamentos...',
  success: 'Mapeamentos carregados com sucesso.',
}).then((res) => res.data);
