import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Row,
  ListGroup,
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ToggleButtonWithDelete from '../../components/ToggleButton';
import Layout from '../../layout/layout';
import {
  getAllQuestion,
} from '../../store/actions/mapping';
import ToggleTooltip from '../../components/Tooltip';
import ProgressBar from '../../components/ProgressBar';
import {
  updateAnwser,
  getByIdActivitySector,
  createAnswer,
} from '../../store/actions/sectors';
import CardInput from '../../components/CardInput';

function CostumerMapping() {
  const [questions, setQuestions] = useState([]);
  const [activity, setActivity] = useState({});
  const [showInputs, setShowInputs] = useState({});

  const calculateQuestionsAnswered = (customActivity) => {
    let count = 0;
    const activityToCount = customActivity || activity;
    activityToCount?.AnswerMappings?.forEach((answer) => {
      if (answer.name !== '') {
        count += 1;
      }
    });
    return count;
  };

  const [currentQuestion, setCurrentQuestion] = useState(1);

  const activityId = localStorage.getItem('@:activityId');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchQuestions() {
      const questionsList = await getAllQuestion();
      const activityloaded = await getByIdActivitySector(activityId);

      setQuestions(questionsList);
      setActivity(activityloaded);
      setCurrentQuestion(calculateQuestionsAnswered(activityloaded));
    }
    fetchQuestions();
  }, []);

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const findAnswersOfQuestion = (id) => activity.AnswerMappings.find(
    (question) => question?.questionId === id,
  );

  const updateActivityAnswers = (resultCreateOrUpdate) => {
    const answerMappingIndex = activity?.AnswerMappings
      ?.findIndex((answerMapping) => answerMapping?.id === resultCreateOrUpdate?.id);
    if (answerMappingIndex !== -1) {
      activity.AnswerMappings[answerMappingIndex] = resultCreateOrUpdate;
    } else {
      activity.AnswerMappings.push(resultCreateOrUpdate);
    }
    setActivity({ ...activity });
  };

  const createOrUpdateAnswerMapping = async (name, idQuestion) => {
    const resultFind = findAnswersOfQuestion(idQuestion);
    let resultCreateOrUpdate;
    if (!resultFind) {
      resultCreateOrUpdate = await createAnswer({ name, questionId: idQuestion, activityId });
    } else {
      let updateString;
      if (resultFind?.name === '') {
        updateString = name;
      } else {
        updateString = `${resultFind?.name},${name}`;
      }
      resultCreateOrUpdate = await updateAnwser({ name: updateString }, resultFind?.id);
    }

    updateActivityAnswers(resultCreateOrUpdate);
  };

  const deleteAnswerMapping = async (name, idQuestion) => {
    const resultFind = findAnswersOfQuestion(idQuestion);
    const answersList = resultFind.name?.toLowerCase().trim().split(',');
    const filterRemovedAnswer = answersList
      .filter((answer) => answer !== name?.toLowerCase().trim());

    const resultCreateOrUpdate = await updateAnwser(
      { name: filterRemovedAnswer.join() },
      resultFind.id,
    );
    updateActivityAnswers(resultCreateOrUpdate);
  };

  const findAnswerMappingByQuestionId = (id) => activity.AnswerMappings.find(
    (answer) => answer?.questionId === id,
  );

  const extractUndefaultTagsFromAnswer = (answer) => {
    const answersList = answer?.name.split(',');
    const defaultTags = questions.find((question) => question?.id === answer?.questionId)?.Answers;
    const filterRemovedAnswer = answersList?.filter((answerFromList) => {
      const findAnswer = defaultTags.find(
        (tag) => tag.name?.toLowerCase().trim() === answerFromList?.toLowerCase().trim(),
      );
      return !findAnswer;
    });
    return filterRemovedAnswer;
  };

  const progress = Math.round((calculateQuestionsAnswered() / questions.length) * 100);

  return (
    <Layout title="Gerador de Mapeamentos" subtitle="Ferramentas">
      <Row>
        <Form>
          <div className="mb-3">
            <p>Porcentage das informações preenchidas:</p>
            <ProgressBar progress={progress} />
          </div>
          <CardInput
            name="descrição"
            label="Descrição da atividade"
            valueInitial={activity?.description}
          />
          {questions?.map((question, index) => (
            index < currentQuestion ? (
              <Card key={question.id} className="my-4">
                <Card.Body>
                  <Form.Group controlId={`question-${question.id}`}>
                    <ToggleTooltip name={`${question.name}*`} description={question.description} />
                  </Form.Group>
                  <Form.Group controlId={`answers-${question.id}`}>
                    <ListGroup>
                      <div className="d-flex flex-wrap">

                        {question?.Answers?.map((answer) => {
                          const resultFind = findAnswersOfQuestion(question.id);
                          return (
                            <ToggleButtonWithDelete
                              onClickButton={async () => {
                                if (!resultFind?.name.split(',').includes(answer.name)) {
                                  createOrUpdateAnswerMapping(answer?.name, question?.id);
                                }
                              }}
                              onDelete={() => {
                                deleteAnswerMapping(answer?.name, question?.id);
                              }}
                              name={answer.name}
                              key={answer.id}
                              idAnswers={answer.id}
                              isChecked={!resultFind?.name?.toLowerCase().trim().split(',').includes(answer.name?.toLowerCase().trim())}
                            />
                          );
                        })}
                      </div>

                      {(() => {
                        const valueInput = extractUndefaultTagsFromAnswer(findAnswerMappingByQuestionId(question.id))?.join(',');
                        return (
                          <>
                            <ToggleButtonWithDelete
                              name="Outros"
                              onDelete={async () => {
                                const answerToChangeName = findAnswerMappingByQuestionId(
                                  question.id,
                                ) || {};
                                const answers = answerToChangeName.name.split(',');
                                const filterRemoveOthers = answers
                                  .filter((answer) => answer !== valueInput);
                                const updatedAnswer = {
                                  ...answerToChangeName,
                                  name: filterRemoveOthers.join(),
                                };
                                await updateAnwser(updatedAnswer, answerToChangeName?.id);
                                updateActivityAnswers(updatedAnswer);

                                setShowInputs((prevState) => ({
                                  ...prevState,
                                  [question.id]: false,
                                }));
                              }}
                              onClickButton={() => {
                                setShowInputs((prevState) => ({
                                  ...prevState,
                                  [question.id]: true,
                                }));
                              }}
                              isChecked={
                                valueInput ? false : !showInputs[question.id]
                              }
                            />
                            {valueInput || showInputs[question.id] ? (
                              <>
                                <Form.Control
                                  className="mt-2"
                                  type="text"
                                  placeholder="Digite a resposta"
                                  value={valueInput}
                                  onChange={async (e) => {
                                    const lastValues = e.target.value.split(/\s*,\s*/);
                                    const answerToChangeName = findAnswerMappingByQuestionId(
                                      question.id,
                                    ) || {};

                                    const defaultTags = questions
                                      ?.find((questionToFindTag) => questionToFindTag
                                        ?.id === answerToChangeName?.questionId)?.Answers;

                                    const filterRemovedAnswer = answerToChangeName?.name?.toLowerCase().trim().split(',').filter((answerFromList) => {
                                      const findAnswer = defaultTags
                                        ?.find((tag) => tag.name
                                          ?.toLowerCase().trim() === answerFromList);
                                      return findAnswer;
                                    }) || [];

                                    const valuesInDefaultTags = lastValues.filter((value) => {
                                      const findAnswer = defaultTags
                                        ?.find(
                                          (tag) => tag.name.trim()
                                            ?.toLowerCase() === value
                                            ?.toLowerCase().trim(),
                                        );
                                      return findAnswer;
                                    });

                                    const newName = [...filterRemovedAnswer, ...lastValues].join(',');
                                    answerToChangeName.name = newName;

                                    if (valuesInDefaultTags.length === 1) {
                                      updateAnwser(
                                        { name: answerToChangeName.name },
                                        answerToChangeName.id,
                                      );
                                    }
                                    updateActivityAnswers(answerToChangeName);
                                  }}
                                />
                                <div>
                                  <Button
                                    variant="outline-success"
                                    onClick={() => {
                                      const answerToSave = findAnswerMappingByQuestionId(
                                        question.id,
                                      );
                                      updateAnwser({ name: answerToSave?.name }, answerToSave?.id);
                                    }}
                                    className="mt-2"
                                  >
                                    Salvar
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </>
                        );
                      })()}

                    </ListGroup>

                  </Form.Group>
                </Card.Body>
              </Card>
            ) : null
          ))}
          <Row>
            <div className="d-flex justify-content-center mt-2">
              {currentQuestion < questions.length ? (
                <>
                  <Button variant="outline-dark" onClick={() => navigate('/tools/sectors/activities')} className="mx-2 w-25">Voltar</Button>
                  <Button onClick={handleNext} className="mx-2 w-25">Próximo</Button>
                </>
              ) : (<Button variant="outline-dark" onClick={() => navigate('/tools/sectors/activities')} className="mx-2 w-25">Voltar</Button>
              )}
            </div>
          </Row>

        </Form>
      </Row>
    </Layout>
  );
}

export default CostumerMapping;
