import React, { useEffect, useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ToggleModel from '../../components/ToggleModel';
import Layout from '../../layout/layout';
import { findMarcas } from '../../store/actions/marcas';
import { createProcess } from '../../store/actions/process';
import { formatStringToLocale } from '../../utils/dateTime';
import ExpandableComponent from '../../components/ExpandDataTable';

function CustomButton({ onClick }) {
  return (
    <Button variant="outline-primary" onClick={onClick}>
      Adicionar processo
    </Button>
  );
}

const getColumns = (handleButtonClick, handleIdMarca) => [
  {
    name: 'Id',
    selector: (row) => row.id,
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Criado em',
    selector: (row) => formatStringToLocale({ date: row.createdAt }),
    sortable: true,
  },
  {
    name: 'Ação',
    cell: (row) => (
      <CustomButton onClick={() => {
        handleButtonClick(true);
        handleIdMarca(row.id);
      }}
      />
    ),
    sortable: false,
  },
];

function GenerateMarcaAdmin() {
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idMarca, setIdMarca] = useState(0);

  const findListMarcas = async () => {
    const result = await findMarcas();
    setList(result);
  };

  useEffect(() => {
    findListMarcas();
  }, []);

  return (
    <Layout title="Gerador de Marcas" subtitle="Ferramentas">
      <Row className="w-80 mx-auto">
        <ToggleModel
          name="Número do processo"
          label="Adicione o id do processo"
          showModal={showModal}
          setShowModal={setShowModal}
          answerId={idMarca}
          addProcess={createProcess}
        />
        <DataTable
          columns={getColumns(setShowModal, setIdMarca)}
          data={list}
          expandableRows
          expandableRowsComponent={ExpandableComponent}
        />
      </Row>
    </Layout>
  );
}

export default GenerateMarcaAdmin;
