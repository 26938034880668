import React from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import LoadDocumentsInCards from '../../components/LoadDocumentsInCards';
import Layout from '../../layout/layout';
import { getFieldsFromBase64TemplateFile, postArrayOfFields, postDocument } from '../../store/actions/documentGenerator';
import { uploadBlobFile } from '../../store/actions/files';

function PrintActionsBar({
  showForm, setShowForm,
}) {
  return (
    <div className="row col-md-12">

      <div className="">
        {showForm === false ? (
          <button onClick={() => setShowForm(true)} type="button" className="btn btn-primary float-end">Criar novo</button>
        ) : (
          <button onClick={() => setShowForm(false)} type="button" className="btn btn-primary">Voltar</button>
        )}
      </div>
    </div>
  );
}

function PrintTable({ showTable }) {
  if (!showTable) {
    return null;
  }
  return (
    <div className="margin-top">
      <LoadDocumentsInCards />

    </div>

  );
}

function PrintForm({ showForm }) {
  if (!showForm) {
    return null;
  }

  const [file, setFile] = React.useState(null);
  const [fields, setFields] = React.useState({});
  const [template, setTemplate] = React.useState({
    name: '',
    description: '',
  });

  const generateEmptyArrayFromFields = (fieldstoArray) => {
    const emptyArray = [];
    Object.keys(fieldstoArray).forEach(() => {
      emptyArray.push({
        name: '',
        label: '',
        placeholder: '',
      });
    });
    return emptyArray;
  };

  const [templateFields, setTemplateFields] = React.useState([]);

  const fileToBase64 = (fileToConvert) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileToConvert);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleFileChange = async (e) => {
    const fileChange = e.target.files[0];
    const base64File = await fileToBase64(fileChange);
    const newFields = await getFieldsFromBase64TemplateFile(base64File);
    setFields(newFields);
    setTemplateFields(generateEmptyArrayFromFields(newFields));
    setFile(fileChange);
  };

  const handleTemplateChange = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const handleTemplateFieldChange = (e, index) => {
    const newTemplateFields = [...templateFields];
    newTemplateFields[index] = { ...newTemplateFields[index], [e.target.name]: e.target.value };
    setTemplateFields(newTemplateFields);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    template.fileId = await uploadBlobFile(file);
    const { id: templateId } = await postDocument(template);
    const templateFieldsWithTemplateId = templateFields
      .map((field) => ({ ...field, templateId }));
    await postArrayOfFields(templateFieldsWithTemplateId);
  };

  return (
    <div className="">
      <div className="col-md-12 text-center">

        <h5>Criar novo documento </h5>
      </div>
      <div className="col-md-12">

        <Form onSubmit={handleFormSubmit}>
          <FormGroup>
            <Form.Label>Arquivo</Form.Label>
            <Form.Control onChange={handleFileChange} required type="file" />
          </FormGroup>
          <FormGroup>
            <Form.Label>Nome</Form.Label>
            <Form.Control name="name" value={template.name} onChange={handleTemplateChange} required type="text" />
          </FormGroup>
          <FormGroup>
            <Form.Label>Descrição</Form.Label>
            <Form.Control name="description" value={template.description} onChange={handleTemplateChange} required type="text" />
          </FormGroup>

          {fields && Object.keys(fields).length > 0 ? (
            <div className="text-center margin-top">
              <h5>Campos</h5>
            </div>
          ) : null}

          {Object.keys(fields).map((field, index) => (
            <div className="row">

              <h6>{field}</h6>
              <div className="col-md-4">
                <FormGroup>
                  <Form.Label>Nome do campo</Form.Label>
                  <Form.Control name="name" value={templateFields[index].name} onChange={(e) => handleTemplateFieldChange(e, index)} required type="text" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Form.Label>Label</Form.Label>
                  <Form.Control name="label" value={templateFields[index].label} onChange={(e) => handleTemplateFieldChange(e, index)} required type="text" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Form.Label>Placeholder</Form.Label>
                  <Form.Control name="placeholder" value={templateFields[index].placeholder} onChange={(e) => handleTemplateFieldChange(e, index)} required type="text" />
                </FormGroup>
              </div>
            </div>

          ))}

          <Button className="margin-top" variant="primary" type="submit">
            Criar
          </Button>
        </Form>

      </div>

    </div>
  );
}

function DocumentGeneratorAdmin() {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <Layout title="Gerador de documentos" subtitle="admin">
      <PrintActionsBar showForm={showForm} setShowForm={setShowForm} />
      <PrintTable showTable={!showForm} />
      <PrintForm showForm={showForm} />
    </Layout>
  );
}

export default DocumentGeneratorAdmin;
