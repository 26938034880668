import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const getFieldsFromBase64TemplateFile = (base64) => toast.promise(api.post('/documentGenerator/fields', { file: base64 }), {
  pending: 'Carregando campos...',
  success: 'Campos carregados com sucesso.',
}).then((res) => res.data);

export const postDocument = (data) => toast.promise(api.post('/documentGenerator', data), {
  pending: 'Gerando documento...',
  success: 'Documento gerado com sucesso.',
}).then((res) => res.data);

export const postArrayOfFields = (fields) => {
  const promises = Promise.all(fields.map((data) => api.post('/documentGeneratorFields', data)));

  return toast.promise(promises, {
    pending: 'Salvando campos...',
    success: 'Campos salvos com sucesso.',
  });
};

export const getAnswersByTemplateFields = (templateFields) => {
  const promises = Promise.all(templateFields.map((data) => api.get(`/documentAnswers/findByTemplateFieldId/${data.id}`)));

  return toast.promise(promises, {
    pending: 'Carregando respostas...',
    success: 'Respostas carregadas com sucesso.',
  }).then((res) => {
    const answers = res.map((answer) => answer.data);

    return answers.filter((answer) => answer !== null).sort((a, b) => a.id - b.id);
  });
};

export const getDocuments = () => toast.promise(api.get('/documentGenerator'), {
  pending: 'Carregando documentos...',
  success: 'Documentos carregados com sucesso.',
}).then((res) => res.data);

export const getDocument = (id) => toast.promise(api.get(`/documentGenerator/${id}`), {
  pending: 'Carregando documento...',
  success: 'Documento carregado com sucesso.',
}).then((res) => res.data);

export const postTemplateAnswer = (data) => toast.promise(api.post('/documentAnswers', data), {
  pending: 'Salvando resposta...',
  success: 'Resposta salva com sucesso.',
}).then((res) => res.data);

export const patchTemplateAnswer = (data) => toast.promise(api.patch(`/documentAnswers/${data.id}`, data), {
  pending: 'Atualizando resposta...',
  success: 'Resposta atualizada com sucesso.',
}).then((res) => res.data);

export const generateDocument = (data) => toast.promise(api.post(`/documentGenerator/generate/${data.id}`), {
  pending: 'Gerando documento...',
  success: 'Documento gerado com sucesso.',
}).then((res) => res.data);

export const getDocumentsGenerated = (params) => toast.promise(api.get('/documentGenerated', {
  params,
}), {
  pending: 'Carregando documentos gerados...',
  success: 'Documentos gerados carregados com sucesso.',
}).then((res) => res.data);
