import React from 'react';

function Title({ title, subtitle }) {
  return (
    <section className="col-md-12 ct-sidebar">

      <h1 className="margin-top">
        {title}
      </h1>

      <h5>{subtitle}</h5>

    </section>

  );
}

export default Title;
