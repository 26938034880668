import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const createUserOfCustumer = async (body) => toast.promise(api.post('/users', body), {
  pending: 'Criando usuário...',
  success: 'Usuário criado com sucesso.',
}).then((res) => res.data);

export const verifyPassword = async (body) => toast.promise(api.post('/users/auth', body), {
  pending: 'Verificando senha...',
  success: 'Senha verificada.',
}).then((res) => res.data);

export const updatePassword = async (userId, body) => toast.promise(api.patch(`/users/${userId}`, body), {
  pending: 'Atualizando Senha',
  success: 'Senha atualizada com sucesso.',
}).then((res) => res.data);

export const getAllUsersOfCustumer = async () => toast.promise(api.get('/users'), {
  pending: 'Procurando usuários...',
  success: 'Usuários encontrados.',
}).then((res) => res.data.rows);

export const getByIdUsersOfCustumer = async () => toast.promise(api.get('/users/self'), {
  pending: 'Procurando usuário...',
  success: 'Usuário encontrado.',
}).then((res) => res.data);

export const updateUserOfCustumer = async (id, body) => toast.promise(api.patch(`/users/${id}`, body), {
  pending: 'Atualizando usuário...',
  success: 'Usuário atualizado.',
}).then((res) => res.data);

export const deleteUserOfCustumer = async (id) => toast.promise(api.delete(`/users/${id}`), {
  pending: 'Deletando usuário...',
  success: 'Usuário deletado.',
}).then((res) => res.data);

export const createUserWithoutCustumer = async (registerForm, invitationId) => {
  if (registerForm.password !== registerForm.repeatPassword) {
    return toast.error('Senha e confirmação de senha não coincidem');
  }

  const formToSubmit = { ...registerForm };

  delete formToSubmit.repeatPassword;
  const register = api.post('/users', {
    ...formToSubmit,
    invitationId,
  });

  return toast.promise(register, {
    pending: 'Cadastrando...',
    success: 'Cadastrado com sucesso',
  });
};
