import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function DocumentCard({ document }) {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">
          <span>
            <FontAwesomeIcon icon={faFile} />
          </span>
          {' '}
          <Link to={`/tools/templates/${document.id}`}>
            {document.name}
          </Link>
        </h3>
      </div>
    </div>
  );
}

export default DocumentCard;
