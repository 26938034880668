import React, { useState } from 'react';
import {
  Form, FormControl, FormGroup, Button, Row,
} from 'react-bootstrap';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../logo-dark.svg';
import { signin } from '../store/actions/auth';

function Signin({ signin: handleSignin, userLogged }) {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: null,
    password: null,
  });

  if (userLogged.isLogged === true) {
    navigate('/');
  }

  return (
    <div style={{ marginTop: '10vh' }}>
      <Row>
        <div className="text-center">
          <img src={logo} alt="Logo" height="250" width="250" />
        </div>
      </Row>
      <Row>

        <div className="d-flex justify-content-center align-items-center">

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              return handleSignin(loginForm).then(() => navigate('/'));
            }}
            className="col-lg-3 col-md-6 col-sm-11 col-11"
          >
            <FormGroup className="mb-3">
              <Form.Label>Email</Form.Label>
              <FormControl required onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })} type="email" placeholder="Enter email" />
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label>Password</Form.Label>
              <FormControl required onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })} type="password" placeholder="Enter password" />
            </FormGroup>

            <Button variant="primary" type="submit">
              Logar
            </Button>

          </Form>
        </div>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { signin },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispathToProps)(Signin);
