import { Button, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt, faFileExcel, faFontAwesomeLogoFull, faMoneyBill1,
} from '@fortawesome/free-regular-svg-icons';
import Layout from '../layout/layout';
import { refreshToken } from '../store/actions/auth';
import { confirmSubscription, getAsaasPaymentLink } from '../store/actions/customer';

function MakeSubscription({ userLogged, refreshToken: refreshTokenDispatcher }) {
  const handleSubscription = () => {
    confirmSubscription().then(() => {
      refreshTokenDispatcher();
    });
  };

  if (userLogged?.Customer?.asaasId === null) {
    return (
      <Layout title="Confirme a sua assinatura">
        <Row className="justify-content-center">
          <div className="col-lg-3 col-md-4">
            <Card>
              <Card.Body className="text-center">
                <Card.Title>Não fique sem os beneficios a baixo</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Apenas R$ 79,99 mensais</Card.Subtitle>
                <div className="mt-3" />
                <Card.Text>
                  <FontAwesomeIcon icon={faFileAlt} size="3x" />
                  <p>Gerador de documentos</p>
                  <FontAwesomeIcon icon={faFileExcel} size="3x" />
                  <p>Gerador de mapeamento de dados</p>
                  <FontAwesomeIcon icon={faFontAwesomeLogoFull} size="3x" />
                  <p>Gerenciador de marcas</p>
                  <FontAwesomeIcon icon={faMoneyBill1} size="3x" />
                  <p>Não gostou ? você tem até 15 dias para cancelar e pedir o reembolso</p>

                  <Button onClick={() => handleSubscription()} variant="primary">Assinar agora</Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Layout>
    );
  }
  const [paymentLink, setPaymentLink] = useState(null);

  useEffect(() => {
    getAsaasPaymentLink().then((response) => {
      setPaymentLink(response.data.paymentLink);
    });
  }, []);

  if (paymentLink) {
    setTimeout(() => {
      getAsaasPaymentLink({
        pending: 'Verificando pagamento...',
        success: 'Verificando pagamento...',
      });
      refreshTokenDispatcher();
    }, 5000);
    return (
      <Layout title="Pagamento">
        <Row className="justify-content-center">
          <div className="col-lg-3 col-md-4">
            <Card>
              <Card.Body className="text-center">
                <Card.Title>Falta só mais um passo para iniciar</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Detectaremos seu pagamento automaticamente e após isso o sistema estará pronto para uso</Card.Subtitle>
                <div className="mt-3" />
                <Card.Text>
                  <a href={paymentLink} target="_blank" rel="noreferrer">
                    <Button variant="primary">Clique aqui para pagar</Button>
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Layout>
    );
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { refreshToken },
    dispatch,
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

export default connect(mapStateToProps, mapDispathToProps)(MakeSubscription);
