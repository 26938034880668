/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Form } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Layout from '../layout/layout';
import { createCustomer } from '../store/actions/customer';
import { refreshToken, updateUser } from '../store/actions/auth';

function CompleteRegister({ userLogged, refreshToken: refreshTokenDispatcher }) {
  const [customer, setCustomer] = useState({
    name: '',
    personType: '',
    birthDate: '',
    nationality: '',
    socialReason: '',
    cpf_cnpj: '',
    zipCode: '',
    phone: '',
  });

  const registerCustomer = async () => {
    const { id } = await createCustomer(customer);
    await updateUser({ ...userLogged, CustomerId: id });
    refreshTokenDispatcher();
  };

  const handleChange = (e) => {
    setCustomer({ ...customer, [e.target.id]: e.target.value });
  };

  return (
    <Layout title="Conclua seu cadastro">
      <Form>
        <Row>

          <div className="form-group col-md-6">
            <label htmlFor="name">Nome</label>
            <input type="text" onChange={handleChange} className="form-control" id="name" placeholder="Nome" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="personType">Tipo de pessoa</label>
            <select onChange={handleChange} className="form-select" id="personType">
              <option selected>Selecione</option>
              <option value={0}>Fisica</option>
              <option value={1}>Juridica</option>
            </select>
          </div>
          {customer.personType === '1' && (
            <div className="form-group col-md-6">
              <label htmlFor="socialReason">Razão social</label>
              <input type="text" onChange={handleChange} className="form-control" id="socialReason" placeholder="Razão social" />
            </div>
          )}

          <div className="form-group col-md-6">
            <label htmlFor="cpf_cnpj">{customer.personType === '1' ? 'CNPJ' : 'CPF'}</label>
            <input type="text" onChange={handleChange} className="form-control" id="cpf_cnpj" placeholder={customer.personType === '1' ? '00.000.000/0000-00' : '000.000.000-00'} />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="birthDate">
              Data de
              {' '}
              {customer.personType === '1' ? 'abertura' : 'nascimento'}
            </label>
            <input type="date" onChange={handleChange} className="form-control" id="birthDate" placeholder="Data de nascimento" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="nationality">Nacionalidade</label>
            <input type="text" onChange={handleChange} className="form-control" id="nationality" placeholder="Ex: Brasileiro" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="zipCode">CEP</label>
            <input type="text" onChange={handleChange} className="form-control" id="zipCode" placeholder="00000-000" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">Telefone</label>
            <input type="text" onChange={handleChange} className="form-control" id="phone" placeholder="(00) 00000-0000" />
          </div>
        </Row>
        <Button onClick={() => registerCustomer()} className="mt-4 float-end">Concluir cadastro</Button>
      </Form>
    </Layout>
  );
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { refreshToken },
    dispatch,
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

export default connect(mapStateToProps, mapDispathToProps)(CompleteRegister);
