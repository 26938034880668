import React, { useState } from 'react';
import {
  Form, FormControl, FormGroup, Button, Row,
} from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../logo-dark.svg';
import { createUserWithoutCustumer } from '../store/actions/users';

function Signup() {
  const [registerForm, setRegisterForm] = useState({});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    createUserWithoutCustumer(registerForm, searchParams.get('invitationCode')).then(() => {
      navigate('/');
    });
  };

  return (
    <div style={{ marginTop: '10vh' }}>
      <Row>
        <div className="text-center">
          <img src={logo} alt="Logo" height="250" width="250" />
        </div>
      </Row>
      <Row>

        <div className="d-flex justify-content-center align-items-center">

          <Form onSubmit={handleSubmit} className="col-lg-3 col-md-6 col-sm-11 col-11">
            <FormGroup className="mb-3">
              <Form.Label>Email</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, email: e.target.value })} type="email" />
            </FormGroup>

            <FormGroup className="mb-3">
              <Form.Label>Nome</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, name: e.target.value })} type="text" />
            </FormGroup>

            <FormGroup className="mb-3">
              <Form.Label>Cargo</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, job: e.target.value })} type="text" />
            </FormGroup>

            <FormGroup className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, phone: e.target.value })} type="text" />
            </FormGroup>

            <FormGroup className="mb-3">
              <Form.Label>Password</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, password: e.target.value })} type="password" minLength={8} />
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <FormControl required onChange={(e) => setRegisterForm({ ...registerForm, repeatPassword: e.target.value })} type="password" minLength={8} />
            </FormGroup>

            <Button variant="primary" type="submit">
              Registrar
            </Button>

          </Form>
        </div>
      </Row>
    </div>
  );
}

export default Signup;
