import React, { useEffect, useState } from 'react';
import { findProcess } from '../store/actions/process';
import { formatStringToLocale } from '../utils/dateTime';

function ExpandableComponent({ data }) {
  const [processList, setProcessList] = useState([]);
  useEffect(() => {
    async function handleBrandsList() {
      const processArray = await findProcess();
      setProcessList(processArray);
    }
    handleBrandsList();
  }, []);

  return (
    <div>
      <span>
        <strong>Natureza</strong>
        :
        {' '}
        {data?.nature}
      </span>
      <br />
      <span>
        <strong>Apresentação</strong>
        :
        {' '}
        {data?.presentation}
      </span>
      <br />
      {
        processList?.map((process) => {
          if (process.brandId === data.id) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '90%',
                  margin: '0.1rem 0',
                }}
              >
                <span>
                  <strong>Número do processo</strong>
                  :
                  {' '}
                  {process.id}
                </span>
                <span>
                  <strong>Data de Atualização</strong>
                  :
                  {' '}
                  { formatStringToLocale({ date: process.updateAt })}
                </span>
                <span>
                  <strong>Status INPI</strong>
                  :
                  {' '}
                  {process?.status}
                </span>
              </div>
            );
          }
          return null;
        })
      }
    </div>
  );
}

export default ExpandableComponent;
