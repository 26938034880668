import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const createProcess = async (body) => toast.promise(api.post('/brands/process', body), {
  pending: 'Criando processo...',
  success: 'Processo criado com sucesso.',
}).then((res) => res.data);

export const findProcess = async () => toast.promise(api.get('/brands/process'), {
  pending: 'Buscando processos...',
  success: 'Processos encontrados com sucesso.',
}).then((res) => res.data.rows);
