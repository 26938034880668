import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const createCustomer = (customer) => toast.promise(api.post('/customers', customer), {
  pending: 'Criando cliente...',
  success: 'Cliente criado com sucesso.',
}).then((res) => res.data);

export const confirmSubscription = () => toast.promise(api.post('/customers/confirm-subscription'), {
  pending: 'Confirmando assinatura...',
  success: 'Assinatura confirmada com sucesso.',
});

export const getAsaasPaymentLink = ({ pending = 'Gerando link de pagamento...', success = 'Link de pagamento gerado com sucesso.' } = {}) => toast.promise(api.get('/customers/paymentLink'), {
  pending,
  success,
});
