/* eslint-disable no-unused-vars */
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import UserLoggedReducer from './reducers/userLoggedReducer';
import modalReducer from './reducers/modalReducer';
import passwordDefaultReducer from './reducers/passwordDefaultReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    userLogged: UserLoggedReducer,
    modal: modalReducer,
    passwordDefault: passwordDefaultReducer,
  }),
  composeEnhancers(applyMiddleware(multi, thunk, promise)),

);
