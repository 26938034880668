import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Button,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
import ToggleButtonWithDelete from '../../components/ToggleButton';
import Layout from '../../layout/layout';
import {
  CreateQuestion, deleteQuestion,
  getAllQuestion, deleteAnswer, CreateAnswer,
} from '../../store/actions/mapping';
import ToggleModel from '../../components/ToggleModel';
import ToggleTooltip from '../../components/Tooltip';

function BackofficeMapping() {
  const [updateGlobal, setUpdateGlobal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ name: '', description: '' });

  const updateItems = () => setUpdateGlobal(newQuestion + 1);

  useEffect(() => {
    async function fetchQuestions() {
      const result = await getAllQuestion();
      setQuestions(result);
      updateItems();
    }
    fetchQuestions();
  }, [updateGlobal]);

  const handleCreateQuestion = async () => {
    const newQuestionObject = await CreateQuestion(newQuestion);

    setQuestions([...questions, newQuestionObject]);
    setNewQuestion({ name: '', description: '' });
  };

  return (
    <Layout title="Gerador de Mapeamentos" subtitle="Ferramentas">
      <Row>
        <Form.Group>
          <Form.Label>Nome da pergunta</Form.Label>
          <Row>
            <Col>
              <Form.Control
                type="text"
                required
                placeholder="Digite o nome da pergunta"
                value={newQuestion.name}
                onChange={(e) => setNewQuestion({ ...newQuestion, name: e.target.value })}
              />
            </Col>
            <Col xs={3}>
              <Button
                variant="primary"
                onClick={handleCreateQuestion}
              >
                Criar pergunta
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Descrição da pergunta</Form.Label>
          <Row>
            <Col>
              <Form.Control
                type="text"
                required
                placeholder="Digite a descrição da pergunta"
                value={newQuestion.description}
                onChange={(e) => setNewQuestion({ ...newQuestion, description: e.target.value })}
              />
            </Col>
          </Row>
        </Form.Group>
        <ToggleModel
          name="Nome da resposta"
          showModal={showModal}
          addAnswer={CreateAnswer}
          setShowModal={setShowModal}
          updateGlobal={() => setUpdateGlobal(0)}
          questionId={questionId}
        />
        <Form>
          {questions?.map((question) => (
            <Card key={question.id} className="my-4">
              <Card.Body>
                <Form.Group controlId={`question-${question.id}`}>

                  <Row>
                    <Col xs={100} className="d-flex justify-content-end">

                      <Button
                        className="mx-1"
                        variant="outline-danger"
                        onClick={async () => {
                          await deleteQuestion(question.id);
                          setUpdateGlobal(0);
                        }}
                      >
                        Deletar pergunta
                      </Button>

                    </Col>

                  </Row>
                  <ToggleTooltip name="Pergunta:" description={question.description} />
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Digite a pergunta"
                        disabled
                        value={question.name}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mt-4" controlId={`answers-${question.id}`}>
                  <Form.Label>Respostas:</Form.Label>
                  <ListGroup>
                    <div className="d-flex flex-wrap">
                      {question?.Answers?.map((answer) => (
                        <ToggleButtonWithDelete
                          onDelete={async () => {
                            await deleteAnswer(answer.id);
                            setUpdateGlobal(0);
                          }}
                          name={answer.name}
                          key={answer.id}
                          idAnswers={answer.id}
                        />
                      ))}
                    </div>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Button
                        className="col-4 mt-3"
                        variant="outline-primary"
                        onClick={() => {
                          setShowModal(true);
                          setQuestionId(question.id);
                        }}
                      >
                        criar outra opção
                      </Button>
                    </Row>
                  </ListGroup>
                </Form.Group>
              </Card.Body>
            </Card>
          ))}
        </Form>
      </Row>
    </Layout>
  );
}

export default BackofficeMapping;
