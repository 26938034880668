import React, { useState, useEffect } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { updateActivitySector } from '../store/actions/sectors';

function CardInput({
  name, label, valueInitial,
}) {
  const [description, setDescription] = useState('');
  const activityId = localStorage.getItem('@:activityId');

  useEffect(() => {
    if (valueInitial) {
      setDescription(valueInitial);
    }
  }, [valueInitial]);

  const handleSave = async () => {
    await updateActivitySector(activityId, { description });
    // toast.success('dados slavos');
  };

  return (
    <Card>
      <Card.Body>
        <Form.Group controlId="description">
          <Form.Label>
            {label}
            *
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Digite aqui a descrição da atividade"
          />
        </Form.Group>
        <Button variant="outline-success" className="mt-2" onClick={handleSave}>
          Salvar
          {' '}
          {name}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default CardInput;
