import { toast } from 'react-toastify';
import api from '../../utils/Api';
import { LOGIN, LOGOUT } from '../types';
import { setToken, getToken, removeToken } from '../../utils/Auth';

export const getAuthInfos = async () => {
  const token = getToken();
  if (token === null) {
    return {
      type: LOGOUT,
    };
  }

  const { data: userInfo } = await api.get('/users/self');

  return {
    type: LOGIN,
    payload: {
      ...userInfo,
      isLogged: true,
    },
  };
};

export const signin = async (loginForm) => {
  const login = api.post('/users/auth', {
    email: loginForm.email,
    password: loginForm.password,
  });

  return toast.promise(login, {
    pending: 'Fazendo login...',
    success: 'Login com sucesso.',
  }).then((res) => {
    setToken(res.data.token);

    return getAuthInfos();
  });
};

export const updateUser = async (user) => toast.promise(api.patch(`/users/${user.id}`, user), {
  pending: 'Atualizando usuário...',
  success: 'Usuário atualizado com sucesso.',
});

export const refreshToken = async () => {
  const res = await api.get('/users/refresh-token');
  const { token } = res.data;
  setToken(token);

  return getAuthInfos();
};

export const signout = () => {
  removeToken();

  return {
    type: LOGOUT,
  };
};
