import React from 'react';
import {
  OverlayTrigger, Tooltip, Form, Button,
} from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ToggleTooltip({ name, description }) {
  return (
    <Form.Label className="d-flex">
      {name}
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{description}</Tooltip>}
      >
        <Button
          style={{ width: '4px', height: '10px', border: 'none' }}
          className="d-flex justify-content-center align-items-center mx-2"
          variant="outline-ligth"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
      </OverlayTrigger>
    </Form.Label>
  );
}

export default ToggleTooltip;
