import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const uploadBlobFile = (blob) => {
  const formData = new FormData();
  formData.append('file', blob);

  return toast.promise(api.post('/files', formData), {
    pending: 'Subindo arquivo...',
    success: 'Arquivo subido com sucesso.',
  }).then((res) => res.data);
};

export const donwloadBlobFile = ({ id, filename }) => {
  api.get(`/files/${id}`, { responseType: 'blob' }).then((res) => {
    const href = URL.createObjectURL(res.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};
