import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../layout/layout';
import { getAllUsersOfCustumer } from '../../store/actions/users';
import FormUser from '../../components/formUser';
import CustomModal from '../../components/modal';
import { openOrCloseModal } from '../../store/actions/modal';
import ContainerPasswordGenererate from '../../components/containerPasswordGenerate';

function CreateUserInBackoffice() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [idUser] = useState(0);
  const [updateData] = useState(false);

  const navigate = useNavigate();

  const isOpenModal = useSelector((state) => state.modal);
  const passHash = useSelector((state) => state.passwordDefault);
  const dispatch = useDispatch();

  const handleModal = (value) => {
    dispatch(openOrCloseModal(value));
  };

  useEffect(() => {
    async function FindUsers() {
      const usersList = await getAllUsersOfCustumer();
      setUsers(usersList);
    }
    FindUsers();
  }, [updateData]);

  useEffect(() => {
    async function FindUserSelected() {
      if (idUser) {
        const findUser = users.find((e) => e.id === idUser);
        setUser(findUser);
      }
    }
    FindUserSelected();
  }, [idUser]);

  return (
    <Layout title="Criar usuário" subtitle="Ferramentas">
      <Row className="w-80 mx-auto">
        <FormUser
          data={user}
          create
          back={() => navigate('/admin/users')}
          modal={() => {
            handleModal(true);
          }}
        />
        <CustomModal
          title="AVISO!"
          isOpen={isOpenModal}
          onClose={() => handleModal(false)}
        >
          <ContainerPasswordGenererate passHash={passHash} handleModal={handleModal} />
        </CustomModal>
      </Row>
    </Layout>
  );
}

export default CreateUserInBackoffice;
