import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function ToggleButtonWithDelete({
  onDelete, name, isChecked, onClickButton,
}) {
  return (
    <div className="d-flex align-items-center mx-1 position-relative">
      <ButtonGroup toggle className="mr-2 mt-2">
        <Button
          className="w-100 rounded-0 rounded-pill position-relative"
          variant={!isChecked ? 'secondary' : 'outline-secondary'}
          onClick={onClickButton}
        >
          {name}
        </Button>
        {!isChecked ? (
          <Button
            className="position-absolute top-0 end-0 rounded-circle"
            variant="danger"
            onClick={onDelete}
            style={{
              width: '10px',
              height: '20px',
              marginTop: '-5px',
              marginRight: '-5px',
              zIndex: 10,
            }}
          >
            <p style={{
              position: 'absolute',
              top: '-5px',
              marginLeft: '-3px',
            }}
            >
              x

            </p>
          </Button>
        ) : null}
      </ButtonGroup>
    </div>

  );
}

export default ToggleButtonWithDelete;
