import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const defaultTimezone = 'America/Sao_paulo';

export const generateDateFromUtc = ({
  date,
  userTimezone = defaultTimezone,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(date).tz(userTimezone);
};

export const formatDayjsToString = (date, format = 'DD/MM/YYYY') => date.format(format);

export const formatStringToLocale = ({
  date,
  userTimezone = defaultTimezone,
}) => {
  const dayjsDate = generateDateFromUtc({ date, userTimezone });
  return formatDayjsToString(dayjsDate);
};
