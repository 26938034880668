import { toast } from 'react-toastify';

export const validationUploadJPEG = (uploadedFile, setFile, setFileName) => {
  const fileType = uploadedFile.type.split('/')[0];

  if (fileType === 'image') {
    const image = new Image();
    image.src = URL.createObjectURL(uploadedFile);

    image.onload = () => {
      if (uploadedFile.type !== 'image/jpeg') {
        toast.error('Envie uma imagem JPEG.');
        setFileName('Nenhum arquivo escolhido');
        setFile(null);
      } else if (uploadedFile.size > 2097152) {
        toast.error('Envie uma imagem com no máximo 2MB.');
        setFileName('Nenhum arquivo escolhido');
        setFile(null);
      } else {
        setFile(uploadedFile);
        setFileName(uploadedFile.name);
      }
    };
  } else {
    toast.error('Deve ser uma imagem');
    setFileName('Nenhum arquivo escolhido');
    setFile(null);
  }
};

export const validationUploadGLobal = (uploadedFile, setFile, setFileName) => {
  setFileName(uploadedFile.name);
  setFile(uploadedFile);
};
