import { LOGIN, LOGOUT } from '../types';

const initialState = {
  isLogged: null,
};

function UserLoggedReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case LOGOUT:
      return {
        isLogged: false,
      };
    default:
      return state;
  }
}

export default UserLoggedReducer;
