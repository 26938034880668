import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

function Step1MarcaForm({
  step, setStep, dataMarca, setDataMarca,
}) {
  const initialName = dataMarca[0]?.name ? dataMarca[0]?.name : '';
  const initialType = dataMarca[0]?.presentation ? dataMarca[0]?.presentation : '';
  const initialNature = dataMarca[0]?.nature ? dataMarca[0]?.nature : '';

  const [name, setName] = useState(initialName);
  const [type, setType] = useState(initialType);
  const [nature, setNature] = useState(initialNature);

  return (
    <Form>
      <Form.Group controlId="name">
        <Form.Label>Nome da marca</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite o nome da marca"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </Form.Group>

      <Form.Group className="mt-3" controlId="type">
        <Form.Label>Apresentação da marca</Form.Label>
        <div className="d-flex flex-column m-1">
          <Form.Check
            inline
            label="Nominativa (sua marca é somente um nome, sem design específico)."
            type="radio"
            id="nominativa"
            value="nominativa"
            checked={type === 'nominativa'}
            onChange={(event) => setType(event.target.value)}
            className="mb-3"
          />
          <Form.Check
            inline
            label="Mista (sua marca é o nome junto com um design específico ou com uma figura)."
            type="radio"
            id="mista"
            value="mista"
            checked={type === 'mista'}
            onChange={(event) => setType(event.target.value)}
            className="mb-3"
          />
          <Form.Check
            inline
            label="Figurativa (sua marca é somente uma figura/símbolo)."
            type="radio"
            id="figurativa"
            value="figurativa"
            checked={type === 'figurativa'}
            onChange={(event) => setType(event.target.value)}
            className="mb-3"
          />
        </div>
      </Form.Group>

      <Form.Group controlId="nature">
        <Form.Label>Natureza da marca</Form.Label>
        <div>
          <Form.Check
            inline
            label="Produto"
            type="radio"
            id="produto"
            value="produto"
            checked={nature === 'produto'}
            onChange={(event) => setNature(event.target.value)}
            className="mb-3"
          />
          <Form.Check
            inline
            label="Serviço"
            type="radio"
            id="servico"
            value="servico"
            checked={nature === 'servico'}
            onChange={(event) => setNature(event.target.value)}
            className="mb-3"
          />
          <Form.Check
            inline
            label="Ambas"
            type="radio"
            id="ambas"
            value="ambas"
            checked={nature === 'ambas'}
            onChange={(event) => setNature(event.target.value)}
            className="mb-3"
          />
        </div>
      </Form.Group>
      <div className="mt-3 mb-3">
        {step < 3 && (
        <Button
          variant="primary"
          type="button"
          onClick={() => (name && type && nature
            ? (() => {
              if (dataMarca[0]) {
                const array = dataMarca;
                const newValue = { name, presentation: type, nature };
                const newArray = [newValue, ...array.slice(1)];
                setDataMarca(newArray);
              } else {
                setDataMarca([...dataMarca, { name, presentation: type, nature }]);
              }
              setStep(step + 1);
            })()
            : toast.error('Preencha todos os campos'))}
        >
          Avançar
        </Button>
        )}
      </div>
    </Form>
  );
}

export default Step1MarcaForm;
