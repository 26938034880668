import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Menu from './menu';
import Sidebar from './sidebar';
import './layout.css';
import Title from './title';

function Layout({ children, title, subtitle }) {
  return (
    <Container className="vh-100" fluid>
      <Row className="vh-100">
        <div className="col-md-2 nopadding bg-dark">
          <Sidebar />
        </div>
        <div className="col-md-10 nopadding-left">
          <div>

            <Menu />
          </div>
          <div style={{ backgroundColor: 'rgb(245 245 245)', padding: '12px' }}>

            <Title title={title} subtitle={subtitle} />
            <div className="col-md-12 margin-top">
              {children}
            </div>
          </div>

        </div>

      </Row>

    </Container>
  );
}

export default Layout;
