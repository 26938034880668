import React, { useEffect, useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Layout from '../../layout/layout';
import { formatStringToLocale } from '../../utils/dateTime';
import { deleteUserOfCustumer, getAllUsersOfCustumer } from '../../store/actions/users';
import FormUser from '../../components/formUser';

function UsersInBackoffice() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const findUsers = async () => {
      const usersList = await getAllUsersOfCustumer();
      setUsers(usersList);
    };
    findUsers();
  }, [updateData]);

  const deleteUser = async () => {
    await deleteUserOfCustumer(user.id);
    window.location.reload();
  };

  const handleBack = () => {
    setUser(null);
    setUpdateData(!updateData);
  };

  const columns = [
    { name: 'Nome', selector: 'name', sortable: true },
    { name: 'Email', selector: 'email' },
    { name: 'Id', selector: 'id' },
    { name: 'Criado em', selector: 'createdAt', sortable: true },
    { name: 'Ação', selector: 'actions', sortable: false },
  ];

  const data = users.map((userRow) => ({
    ...userRow,
    createdAt: formatStringToLocale(userRow.createdAt),
    actions: (
      <Button variant="outline-primary" onClick={() => setUser(userRow)}>
        Detalhes do usuário
      </Button>
    ),
  }));

  return (
    <Layout title="Lista de usuários" subtitle="Ferramentas">
      <Row className="w-80 mx-auto">
        {user ? (
          <FormUser
            data={user}
            isList
            onDelete={deleteUser}
            back={handleBack}
          />
        ) : (
          <>
            <DataTable columns={columns} data={data} />
            <div className="d-flex justify-content-end mt-4">
              <Button variant="secondary" onClick={() => navigate('new')}>
                Cadastrar
              </Button>
            </div>
          </>
        )}
      </Row>
    </Layout>
  );
}

export default UsersInBackoffice;
