import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import InfoCard from '../../components/InfoCard';
import Layout from '../../layout/layout';
import { findMarcas } from '../../store/actions/marcas';
import { formatStringToLocale } from '../../utils/dateTime';
import ExpandableComponent from '../../components/ExpandDataTable';

const columns = [
  {
    name: 'Id',
    selector: (row) => row.id,
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Criado em',
    selector: (row) => formatStringToLocale({ date: row.createdAt }),
    sortable: true,
  },
];

function GenerateMarca() {
  const [list, setList] = useState([]);

  const navigate = useNavigate();
  const findListMarcas = async () => {
    const result = await findMarcas();
    setList(result);
  };

  useEffect(() => {
    findListMarcas();
  }, []);
  return (
    <Layout title="Gerador de Marcas" subtitle="Ferramentas">
      <Row className="w-80 mx-auto">
        <Col className="w-2">
          <InfoCard
            title="Como funciona?"
            info="Ao clicar em ''Registrar Marca'', vamos pedir algumas informações e pronto. O resto é com a gente."
            infoSecundary="Nessa mesma tela vamos te mostrar os status dos seus pedidos para te atualizar."
          />
        </Col>
        <Col className="w-2">
          <InfoCard
            title="O que significa cada status do INPI?"
            info={(
              <span>
                <strong>REGISTRADA:</strong>
                {' '}
                Sua marca está registrada no INPI.
              </span>
            )}
            infoSecundary={(
              <span>
                <strong>PEDIDO em Análise:</strong>
                {' '}
                Seu pedido aguarda a decisão do INPI.
              </span>
            )}
            infoEnd={(
              <span>
                <strong>AGUARDANDO PROTOCOLO:</strong>
                {' '}
                O protocolo no INPI ainda não foi realizado.
              </span>
            )}
          />
        </Col>
        <Row className="mt-5 mb-3">
          <Col>
            <Button
              className="btn-lg btn-block mb-3"
              variant="primary"
              onClick={() => navigate('/marcas/new')}
            >
              Registrar Marca

            </Button>
          </Col>
        </Row>
        <DataTable
          columns={columns}
          data={list}
          expandableRows
          expandableRowsComponent={ExpandableComponent}
        />
      </Row>
    </Layout>
  );
}

export default GenerateMarca;
