import React from 'react';
import {
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Home from './pages/home';
import Signup from './pages/signup';
import Signin from './pages/signin';
import DocumentGenerator from './pages/tools/documentGenerator';
import DocumentGeneratorAdmin from './pages/admin/documentGeneratorAdmin';
import DocumentGeneratorDetail from './pages/tools/documentGeneratorDetail';
import DocumentGeneratorQuestions from './pages/tools/documentGeneratorQuestions';
import BackofficeMapping from './pages/admin/mapping';
import Sectors from './pages/tools/sectors';
import SectorActivities from './pages/tools/sectorActives';
import CostumerMapping from './pages/tools/CostumerMapping';
import GenerateMarca from './pages/tools/GenerateMarca';
import MarcaForm from './pages/tools/newMarca';
import GenerateMarcaAdmin from './pages/admin/GenerateMarca';
import { getAuthInfos } from './store/actions/auth';
import CompleteRegister from './pages/completeRegister';
import UsersOfCustomer from './pages/tools/UsersOfCustomer';
import UsersInBackoffice from './pages/admin/Users';
import CreateUserInBackoffice from './pages/admin/NewUser';
import MakeSubscription from './pages/MakeSubscription';

const backofficeRoutes = [
  {
    path: '/admin/tools/templates',
    element: <DocumentGeneratorAdmin />,
  },
  {
    path: '/admin/tools/mapping',
    element: <BackofficeMapping />,
  },
  {
    path: '/admin/tools/mapping',
    element: <BackofficeMapping />,
  },
  {
    path: '/admin/marcas',
    element: <GenerateMarcaAdmin />,
  },
  {
    path: '/admin/users',
    element: <UsersInBackoffice />,
  },
  {
    path: '/admin/users/new',
    element: <CreateUserInBackoffice />,
  },
];

const customerRoutes = [
  {
    path: '/tools/templates',
    element: <DocumentGenerator />,
  },
  {
    path: '/tools/templates/:id/questions',
    element: <DocumentGeneratorQuestions />,
  },
  {
    path: '/tools/templates/:id',
    element: <DocumentGeneratorDetail />,
  },
  {
    path: '/tools/sectors',
    element: <Sectors />,
  },
  {
    path: '/tools/sectors/activities',
    element: <SectorActivities />,
  },
  {
    path: '/tools/mapping',
    element: <CostumerMapping />,
  },
  {
    path: '/marcas',
    element: <GenerateMarca />,
  },
  {
    path: '/marcas/new',
    element: <MarcaForm />,
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/users',
    element: <UsersOfCustomer />,
  },
];

const publicRoutes = [
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/signin',
    element: <Signin />,
  },
];

const renderRouter = ({ userLogged, getAuthInfos: getAuthInfosDispatch }) => {
  if (userLogged.isLogged === null) {
    getAuthInfosDispatch();
    return null;
  }
  let routesMerged = [...publicRoutes];
  if (userLogged.isLogged === true) {
    routesMerged = [...customerRoutes, ...routesMerged];
    if (userLogged.isBackoffice === true) {
      routesMerged = [...backofficeRoutes, ...routesMerged];
    }
    routesMerged.push({
      path: '*',
      element: (
        <meta httpEquiv="refresh" content="0; url=/" />
      ),
    });
    if (userLogged.CustomerId === null) {
      routesMerged = [{
        path: '*',
        element: <CompleteRegister />,
      }];
    }
    if (userLogged?.Customer?.activated === false) {
      routesMerged = [{
        path: '*',
        element: <MakeSubscription />,
      }];
    }
  } else {
    routesMerged.push({
      path: '*',
      element: (
        <meta httpEquiv="refresh" content="0; url=#/signin" />
      ),
    });
  }
  const router = createHashRouter(routesMerged);
  return (
    <RouterProvider router={router} />
  );
};

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { getAuthInfos },
    dispatch,
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

export default connect(mapStateToProps, mapDispathToProps)(renderRouter);
