import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function UploadForm({
  setFile, title, rules, validation,
}) {
  const [fileName, setFileName] = useState('Nenhum arquivo escolhido');

  const handleInputChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (validation) {
      validation(uploadedFile, setFile, setFileName);
    }
  };

  return (
    <Form>
      <Form.Group>
        <div style={{ position: 'relative' }}>
          <Form.Label>{title}</Form.Label>
          <Form.Control style={{ color: 'transparent', position: 'relative' }} type="file" onChange={handleInputChange} />
          <div style={{ position: 'absolute', bottom: '-0.5rem', left: '10rem' }}>
            <p>
              {fileName.toUpperCase()}
            </p>
          </div>
        </div>
        <Form.Text>
          <span style={{ fontSize: '0.7rem' }}>{rules}</span>
        </Form.Text>
      </Form.Group>
    </Form>

  );
}

export default UploadForm;
