import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

function TitularMarcaForm({
  step, setStep, dataMarca, setDataMarca,
}) {
  const initialMarcaPerson = dataMarca[1]?.marcaPerson ? dataMarca[1]?.marcaPerson : false;
  const initialIsPJ = dataMarca[1]?.isPJ ? dataMarca[1]?.isPJ : false;
  const initialNamePerson = dataMarca[1]?.namePerson ? dataMarca[1]?.namePerson : '';
  const initialDocument = dataMarca[1]?.document ? dataMarca[1]?.document : '';
  const initialZipCode = dataMarca[1]?.zipCode ? dataMarca[1]?.zipCode : '';
  const initialUf = dataMarca[1]?.uf ? dataMarca[1]?.uf : '';
  const initialCity = dataMarca[1]?.city ? dataMarca[1]?.city : '';
  const initialAddress = dataMarca[1]?.address ? dataMarca[1]?.address : '';
  const initialEmailINPI = dataMarca[1]?.emailINPI ? dataMarca[1]?.emailINPI : '';
  const initialCountry = dataMarca[1]?.country ? dataMarca[1]?.country : '';

  const [marcaPerson, setMarcaPerson] = useState(initialMarcaPerson);
  const [isPJ, setIsPJ] = useState(initialIsPJ);
  const [namePerson, setNamePerson] = useState(initialNamePerson);
  const [cpfCnpj, setCpfCnpj] = useState(initialDocument);
  const [cep, setCep] = useState(initialZipCode);
  const [uf, setUf] = useState(initialUf);
  const [city, setCity] = useState(initialCity);
  const [address, setAddress] = useState(initialAddress);
  const [email, setEmail] = useState(initialEmailINPI);
  const [country, setCountry] = useState(initialCountry);

  const isValidEmail = (_email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(_email);
  };

  const handleSubmit = () => {
    if (dataMarca[1]) {
      const array = dataMarca;
      const newValue = {
        marcaPerson,
        isPJ,
        document: cpfCnpj,
        zipCode: cep,
        uf,
        city,
        address,
        emailINPI: email,
        country,
        namePerson,
      };
      const newArray = [...array.slice(0, 1), newValue, ...array.slice(2)];
      setDataMarca(newArray);
    } else {
      setDataMarca([...dataMarca, {
        marcaPerson,
        isPJ,
        document: cpfCnpj,
        zipCode: cep,
        uf,
        city,
        address,
        emailINPI: email,
        country,
        namePerson,
      }]);
    }
    setStep(step + 1);
  };

  const handleNext = () => {
    if (!marcaPerson || !email) {
      return toast.error('Preencha todos os campos');
    }
    if (!isValidEmail(email)) {
      return toast.error('Email inválido');
    }
    if (marcaPerson === 'isPerson') {
      return setStep(step + 1);
    }
    return cpfCnpj && cep && uf && city && address && email
      ? (() => {
        handleSubmit();
        setStep(step + 1);
      })()
      : toast.error('Preencha todos os campos');
  };

  return (
    <Form>
      <Form.Group>
        <Form.Label>O titular da marca será o mesmo da conta logada?</Form.Label>
        <div className="d-flex flex-column m-1">
          <Form.Check
            inline
            label="Sim"
            type="radio"
            id="isPerson"
            value="isPerson"
            checked={marcaPerson === 'isPerson'}
            onChange={(event) => setMarcaPerson(event.target.value)}
            className="mb-3"
          />
          <Form.Check
            inline
            label="Não"
            type="radio"
            id="noPerson"
            value="noPerson"
            checked={marcaPerson === 'noPerson'}
            onChange={(event) => setMarcaPerson(event.target.value)}
            className="mb-3"
          />
        </div>
      </Form.Group>
      {
        marcaPerson === 'noPerson' ? (
          <>
            <Form.Group>
              <Form.Label>Titular da marca</Form.Label>
              <div className="d-flex flex-column m-1">
                <Form.Check
                  inline
                  label="Pessoa Física"
                  type="radio"
                  id="pf"
                  value="pf"
                  checked={!isPJ}
                  onChange={(event) => setIsPJ(event.target.value === 'pj')}
                  className="mb-3"
                />
                <Form.Check
                  inline
                  label="Pessoa Jurídica"
                  type="radio"
                  id="pj"
                  value="pj"
                  checked={isPJ}
                  onChange={(event) => setIsPJ(event.target.value === 'pj')}
                  className="mb-3"
                />
              </div>
            </Form.Group>

            <Form.Group controlId="nome">
              <Form.Label>Digite o nome do titular</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                value={namePerson}
                onChange={(event) => setNamePerson(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="cpfCnpj">
              <Form.Label>O CPF ou CNPJ do titular</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CPF ou CNPJ"
                value={cpfCnpj}
                onChange={(event) => setCpfCnpj(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="cep">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CEP"
                value={cep}
                onChange={(event) => setCep(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="uf">
              <Form.Label>UF</Form.Label>
              <Form.Control
                placeholder="Digite a UF"
                value={uf}
                onChange={(event) => setUf(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="country">
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o país"
                value={country}
                onChange={(event) => setCountry(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="cidade">
              <Form.Label>Cidade do titular</Form.Label>
              <Form.Control
                placeholder="Digite a cidade"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="endereço">
              <Form.Label>Endereço do titular</Form.Label>
              <Form.Control
                placeholder="Digite o endereço"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="Email">
              <Form.Label>Email do titular da marca</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
          </>
        ) : (
          <Form.Group controlId="Email">
            <Form.Label>Email do titular da marca</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
        )
      }
      <div className="mt-3 mb-3">
        {step > 1 && (
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => {
              setStep(step - 1);
              // setDataMarca([]);
            }}
          >
            Voltar
          </Button>
        )}
        {step < 3 && (
        <Button
          className="mx-4"
          variant="primary"
          type="button"
          onClick={() => {
            handleSubmit();
            handleNext();
          }}
        >
          Avançar
        </Button>
        )}
      </div>
    </Form>
  );
}

export default TitularMarcaForm;
