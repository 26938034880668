import React from 'react';
import './menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import {
  Navbar, Nav, Container, Dropdown,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signout } from '../store/actions/auth';

function Menu({ userLogged, signout: signoutDispatcher }) {
  const InicialsOfUserName = userLogged?.name?.split(' ').map((word) => word[0]).join('');

  return (
    <Navbar bg="light" expand="sm">
      <Container fluid>
        <Navbar.Toggle aria-controls="mynavbar" />
        <Navbar.Collapse id="mynavbar">
          <Nav className="me-auto" />
          <div className="ms-auto">
            <FontAwesomeIcon style={{ height: '1.7rem' }} icon={faBell} />
          </div>
          <div className="ms-3" />
          <Dropdown>
            <Dropdown.Toggle
              className="d-flex align-items-center justify-content-center"
              variant="light"
              id="dropdown-basic"
            >
              <div className="circledName">
                <span>{InicialsOfUserName}</span>
              </div>

              <div className="ms-3">
                {userLogged?.name}
                <Dropdown.Menu>
                  <Dropdown.Item href="#/users">Gerenciar conta</Dropdown.Item>
                  <Dropdown.Item onClick={() => signoutDispatcher()}>Sair</Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { signout },
    dispatch,
  );
}

const mapStateToProps = (state) => ({ userLogged: state.userLogged });

export default connect(mapStateToProps, mapDispathToProps)(Menu);
