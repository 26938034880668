import { toast } from 'react-toastify';
import api from '../../utils/Api';

export const CreateSector = async (bodySector) => toast.promise(api.post('/sector', bodySector), {
  pending: 'Criando setor...',
  success: 'Setor criado com sucesso.',
}).then((res) => res.data);

export const getAllSector = async () => toast.promise(api.get('/sector'), {
  pending: 'Buscando setores...',
  success: 'Setor encontrado com sucesso.',
}).then((res) => res.data);

export const getByIdSector = async (id) => toast.promise(api.get(`/sector/${id}`), {
  pending: 'Pesquisando setor...',
  success: 'Setor encontrado com sucesso.',
}).then((res) => res.data);

export const deleteSector = async (id) => toast.promise(api.delete(`/sector/${id}`), {
  pending: 'Deletando setor...',
  success: 'Setor deletado com sucesso.',
}).then((res) => res.data);

export const CreateActivitySector = async (bodyActivity) => toast.promise(api.post('/sector/activity', bodyActivity), {
  pending: 'Criando atividade para o setor...',
  success: 'Atividade criada com sucesso.',
}).then((res) => res.data);

export const getByIdActivitySector = async (id) => toast.promise(api.get(`/sector/activity/${id}`), {
  pending: 'Pesquisando atividade ...',
  success: 'Atividade encontrada com sucesso.',
}).then((res) => res.data);

export const updateActivitySector = async (id, bodyActivity) => toast.promise(api.patch(`/sector/activity/${id}`, bodyActivity), {
  pending: 'Criando descrição da atividade ...',
  success: 'Descrição da atividade criada com sucesso.',
}).then((res) => res.data);

export const deleteActivitySector = async (id) => toast.promise(api.delete(`/sector/activity/${id}`), {
  pending: 'Deletando atividade...',
  success: 'Atividade deletada com sucesso.',
}).then((res) => res.data);

export const createAnswer = async (bodyAnswer) => toast.promise(api.post('/sector/activity/answer', bodyAnswer), {
  pending: 'Criando resposta...',
  success: 'Resposta criada com sucesso.',
}).then((res) => res.data);

export const getAllAnwser = async () => toast.promise(api.get('/sector/activity/answer'), {
  pending: 'Buscando respostas...',
  success: 'Respostas Encontrada com sucesso.',
}).then((res) => res.data.rows);

export const getByIdAnwser = async (id) => toast.promise(api.get(`/sector/activity/answer/${id}`), {
  pending: 'Buscando resposta...',
  success: 'Resposta Encontrada com sucesso.',
}).then((res) => res.data);

export const updateAnwser = async (bodyAnswer, id) => toast.promise(api.patch(`/sector/activity/answer/${id}`, bodyAnswer), {
  pending: 'Atualizando resposta...',
  success: 'Resposta atualizada com sucesso.',
}).then((res) => res.data);
