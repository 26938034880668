import React, { useEffect, useState } from 'react';
import {
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1MarcaForm from '../../components/Step1Marca';
import Step2MarcaForm from '../../components/Step2Marca';
import Step3MarcaForm from '../../components/Step3Marca';
import Layout from '../../layout/layout';
import { createMarcas } from '../../store/actions/marcas';

function MarcaForm() {
  const [step, setStep] = useState(1);
  const [dataMarca, setDataMarca] = useState([]);
  const [marca, setMarca] = useState(false);
  const navigate = useNavigate();

  const handleMarca = async () => {
    if (marca) {
      const objUnic = dataMarca
        .reduce((objEnd, objPrimary) => ({ ...objEnd, ...objPrimary }), {});
      await createMarcas(objUnic);
      setMarca(false);
      navigate('/marcas');
    }
  };

  useEffect(() => {
    handleMarca();
  }, [marca]);

  return (
    <Layout title="Criar Marca" subtitle="Ferramentas">
      <Row className="w-80 mx-auto">
        {step === 1 && (
        <Step1MarcaForm
          step={step}
          setStep={setStep}
          dataMarca={dataMarca}
          setDataMarca={setDataMarca}
        />
        )}
        {step === 2 && (
        <Step2MarcaForm
          step={step}
          setStep={setStep}
          dataMarca={dataMarca}
          setDataMarca={setDataMarca}
        />
        )}

        {step === 3 && (
        <Step3MarcaForm
          step={step}
          setStep={setStep}
          dataMarca={dataMarca}
          setDataMarca={setDataMarca}
          marca={marca}
          setMarca={setMarca}
        />
        )}
      </Row>
    </Layout>
  );
}

export default MarcaForm;
